/**
 * File: styles.css
 * Description : Contains all necessary styling for the Knowledge Desk - Responsive Knowledgebase HTML5 Template
 * Author: xenioushk
 * URL: http://www.bluewindlab.net
 * Project: Knowledge Desk - Responsive Knowledgebase HTML5 Template
 * Version: 1.0.2
 * Last Update: 14-04-2017
 **/

/*========================================================================*/
/*   TABLE OF CONTENT
/*========================================================================*/
/*
/*      01. GENERAL STYLING
/*      02. HEADER
/*      03. MAIN NAVIGATION MENU
/*      04. HOME PAGE
/*      05. FORUM LAYOUT
/*      06. FAQ PAGE
/*      07. KNOWLEDGEBASE SINGLE PAGE
/*      08. BLOG LAYOUT
/*      09. BLOG SINGLE PAGE
/*      10. ELEMENTS
/*      11. 404 PAGE
/*      12. FOOTER
/*      13. SIDEBAR & WIDGETS
/*      14. RESPONSIVE STYLING
/*
/*========================================================================*/

@import 'variables.less';
@import 'mixins.less';
@import 'reset.less';

/***********************************************************************************************/
/* 01. GENERAL STYLING  */
/***********************************************************************************************/

body {
    font: 16px/24px @body-font;
    color: lighten(@default-font-color, 15%);
    font-style: normal;
    font-weight: 400;
    position: relative;
}

img{
    max-width: 100%;
}

a {
    color: @primary-color;
    text-decoration: none;   
    line-height: 24px;
    .transition(all, 0.3s);
}

a:hover,
a:focus {

    color: darken(@primary-color, 20%);
    text-decoration: none;

}

blockquote{
    font: @body-font;
    color: @text-color;
}

h1, h2, h3, h4, h5, h6 {    
    font-family: @heading-font;
    font-style: normal;
    font-weight: 600;
    color: @heading-font-color;
    line-height: 36px;
    margin: 0 0 16px 0;
}


h1{

    font-size: 56px;

}

h2{

    font-size: 48px;

}

h2+p{

    margin-top: 12px;
}


h3{

    font-size: 36px;

}

h3+p{

    margin-top: 12px;
}


h4{

    font-size: 24px;

}

h4+p{

    margin-top: 12px;
}

h5{

    font-size: 20px;

}

h6{

    font-size: 16px;

}

* + p {
    margin-top: 12px;
}

p {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 12px;
    padding: 0;
    word-spacing: 0.003em;
}

ul, li{

    list-style-type: none;
}

input[type=text],
input[type=password],
input[type=submit],
input[type=button],
textarea{

    .round(0);
    .drop-shadow(0, 0, 0, 0, 0);

    &:focus{

        .drop-shadow(0, 0, 0, 0, 0);

    }

}

strong{
    font-weight: 600;
}

/*----- CUSTOM MARGIN ----*/

.no-margin{
    margin: 0px !important;
}

.margin-top-5{
    margin-top: 5px;
}

.margin-top-12{
    margin-top: 12px !important;
}

.margin-top-24{
    margin-top: 24px !important;
}

.margin-top-32{
    margin-top: 32px !important;
}

.margin-bottom-5{
    margin-bottom: 5px;
}

.margin-bottom-12{
    margin-bottom: 12px !important;
}

.margin-bottom-24{
    margin-bottom: 24px !important;
}

.margin-bottom-32{
    margin-bottom: 32px !important;
}

.margin-bottom-80{
    margin-bottom: 80px;
}

.margin-right-0{
    margin-right: 0px;
}

/*----- CUSTOM PADDING ----*/

.no-gutter{
     [class^="col-"] {
        padding: 0px;
    }
}

.no-padding{
    padding: 0px !important;
}

.padding-left-0{
    padding-left: 0px;
}

.padding-right-0{
    padding-right: 0px;
}

.padding-right-5{
    padding-right: 5px;
}

.padding-right-11{
    padding-right: 11px;
}

/*----- PRE LOADER ----*/

#preloader{
    background: rgba(255,255,255,0.99);
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 9999;

    span{
    left: 50%;
    margin-left: -15px;
    margin-top: -15px;
    position: absolute;
    top: 50%;
}
}

// BACK TO TOP

#backTop{
    width:16px;
    height:16px;
    padding:10px;
    border-radius:0px;
    text-indent:-9999px;
    cursor:pointer;
    z-index:999999999;
    display:none;
    box-sizing:content-box;
    -webkit-box-sizing:content-box;
    opacity: 1;
    .transition(opacity, 0.2s);
    &:hover{
        opacity: 1;
    }
}

#backTop:after{
    position: absolute;
    content: '-';
    width: 16px;
    height: 16px;
    text-align: center;
    top: 0;
    left: 0;
    color: #FFF;
}

#backTop.custom{

    background-color: @primary-color;
    background-image: url('../images/uparr-48-b.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border: 0px solid lighten(@primary-color, 20%);
    .transition( 0.2s );

    &:hover{
        background-color:  rgba(255,255,255,1);
        border: 0px solid darken(@primary-color, 20%);
    }
}

// COMMON UTILITY CLASSES

.text-primary-color{
    color: @primary-color;
}

.text-light-color{
    color: @light-text-color;
}

.text-dark-color{
    color: @dark-text-color;
}

.text-highlighter-white{
    font-weight: 500;
}

.text-bold{
    font-weight: bold;
}

.text-highlighter{
    color: @primary-color;
}

.text-highlighter-white{
    color: @light-text-color;
}

.capitalize{
    text-transform: capitalize;
}

.uppercase{
    text-transform: uppercase;
}

.alert,
.progress,
.pre {
    .round(0px);
}

hr{
    margin: 0px;
    border: 0px;
    border: 0px;
    height: 1px;
    background: lighten( @light-border-color,5%);
}

.no-border{
    border: 0px !important;
}

.margin-top-5{
    margin-top: 5px;
}

.margin-top-11{
    margin-top: 11px;
}

.margin-bottom-5{
    margin-bottom: 5px;
}

.margin-bottom-11{
    margin-bottom: 11px;
}

.padding-left-0{
    padding-left: 0px;
}

.padding-right-0{
    padding-right: 0px;
}

.padding-right-5{
    padding-right: 5px;
}

.padding-right-11{
    padding-right: 11px;
}

.no-padding{
    padding: 0px;
}

.no-border{
    border: 0px solid #FFFFFF !important;
}


.inline {
    li {
        display: inline-block;
        margin-right: 24px;

        &:last-child {
            margin-right: 0;
        }
    }
}


.narrow-p {
    margin: 0 auto 1.5em;

    width: 30%;
}

.fr { float: right;}
.fl { float: left;}
.cb { clear: both;}
.db{display: block;}

.overflow-hidden{
    overflow: hidden;
}

span.border-light-dotted{
    border-bottom: 1px dotted @primary-color;
}

span.border-bold-dashed{
    border-bottom: 2px dashed @primary-color;
}

// BLOCKQUOTE

blockquote {
    padding: 12px 26px 26px 42px;
    margin: 0 0 20px;
    font-size: 14px;
    font-family: @body-font;
    background: @pure-white-bg;
    position: relative;
    border: 1px solid @light-border-color;
    border-left: 4px solid darken(@light-border-color, 50%);
    .transition(0.3s);

    &:hover{

        border: 1px solid darken(@light-border-color,15%);
        border-left: 4px solid darken(@light-border-color, 50%);

    }

    p {

        font-size: 14px;
        line-height: 24px;


    }

}

blockquote:before {
    display: block;
    position: absolute;
    left: 12px;
    top: 14px;
    content: "\f10d";
    font-family: 'FontAwesome';
    font-size: 15px;
    color: #d6d6d6;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

blockquote.testimonial {
    background: @pure-white-bg;
    border-top: 1px solid @light-border-color;
    border-right: 1px solid @light-border-color;
    border-bottom: 1px solid @light-border-color;
    margin-bottom: 0;
}

cite {
    text-align: right;
    display: block;
}

cite:before { 
    margin-right: 4px;
}

.bq-author {
    padding: 16px 0 0 110px;
    position: relative;
}

.bq-author:before {
    content: "";
    display: block;
    position: absolute;
    top: -1px;
    left: 69px;
    width: 0;
    height: 0;
    border-left: 22px solid transparent;
    border-right: 0 solid transparent;
    border-top: 22px solid #dcdcdc;
    z-index: 2;
}

.bq-author:after {
    content: "";
    display: block;
    position: absolute;
    top: -1px;
    left: 70px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 0 solid transparent;
    border-top: 20px solid #fff;
    z-index: 3;
}

.bq-author h6 {
    margin-bottom: 6px;
}

.bq-author .bq-author-info {
    font-size: 12px;
}

// ORDERED/UNORDERED LISTS

ol {

    li {
        padding: 0;
        margin: 0 0 10px 25px;
        list-style-type: decimal;
    }
}


.section-container{
    margin: 24px 0 0 0;
}

.section-heading{
    position: relative;
    font-size: 24px;
    margin-bottom: 24px;
    text-transform: uppercase;
    font-family: @heading-font-alt;
    letter-spacing: 0.03em;
    font-weight: 700;
    color: lighten(@heading-font-color, 2%);

    &:before{
        position: absolute;
        content: '';
        width: 120px;
        height: 24px;
        display: block;
        top: 36px;
        left: 50%;
        margin-left: -60px;
        background-image: url("../images/heading_seperator_alt.png");
        background-repeat: no-repeat;
        background-position: center center;
    }

    span{
        font-style: 400;
        color: lighten(@heading-font-color, 0%);
    }

}

.section-subheading{
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: lighten(@default-font-color, 3%);
    padding: 0px 42px;
    margin-bottom: 32px;
}

// Sub Heading Alter. Use For Dark Background.

.section-heading-alt{
    position: relative;
    font-size: 24px;
    margin-bottom: 24px;
    text-transform: uppercase;
    font-family: @heading-font-alt;
    letter-spacing: 0.03em;
    font-weight: 700;
    color: lighten(@light-text-color, 2%);

    &:before{
        position: absolute;
        content: '';
        width: 120px;
        height: 24px;
        display: block;
        top: 36px;
        left: 50%;
        margin-left: -60px;
        background-image: url("../images/heading_seperator_alt.png");
        background-repeat: no-repeat;
        background-position: center center;
    }

    span{
        font-style: 400;
        color: lighten(@light-text-color, 2%);
    }

}

.section-subheading-alt{
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color:  darken(@light-text-color, 1%);
    padding: 0px 42px;
    margin-bottom: 32px;
}

.section-header {
    margin: 0 0 62px 0;
    text-align: center;

}
.section-header .section-title {
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
}

.section-header .section-desc {
    text-transform: capitalize;
    color: darken( @light-text-color, 25%);
}

.topic-bold-header{

    margin-bottom: 24px;

    h2, h3, h4{
        margin: 0;
        text-transform: uppercase;
        position: relative;
        float: left;
        display: inline-block;
        font-size: 24px;
        color: @heading-font-color;
    }

    h2{

        font-size: 24px;
        color: @heading-font-color;

    }

    h3{

        color: @heading-font-color;

    }

}

// ACCORDION

.accordion .panel{
    margin-bottom: 3px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
}

.accordion .panel .panel-title a{
    display: block;
}

span.label {
    margin-right: 1px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    font-size: 100%;
}

// BUTTONS

#btn,
.btn {
    text-align: center;
    .transition(0.3s);

    &:hover{

        color: #000000;

    }

}


.btn-custom{
    text-align: center;
    .transition(0.3s);
    border: 1px solid @light-border-color;
    background: @primary-color;
    color: @light-text-color;
    .round(0px);
    padding: 12px 14px;

    &:hover{
        border-color: darken(@light-border-color,15%);
        color: @primary-color;
        background: @pure-white-bg;
    }

}

a.btn-load-more{
    .round(0px);
    background: transparent;
    color: @primary-color;
    background: @pure-white-bg;
    border: 1px solid @primary-color;
    padding: 12px 24px;
    font-weight: 600;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    .transition(0.3s);

    &:hover{
        color: @light-text-color;
        background: @primary-color;
        border: 1px solid @primary-color;
    }
}

a.btn-sm-load-more{
    margin-top: 12px;
    letter-spacing: 0.01em;
}


.button{
    display: inline-block;
    .round();
    color:white;
    font-weight: bold;	
    height: 3em;
    line-height: 1em;
    padding: 1em;

}

.small-button{
    display: inline-block;
    .round(0px);
    color:@dark-color;
    font-weight: bold;	
    height: 0;
    line-height: 0;
    padding: 1em 1em;
    margin: 6px 0;
    width: 100%;

}

// Text highlighter.

.text-highlighter{
    color: @primary-color;
}

.text-highlighter-white{
    color: @light-text-color;
}

#comment_btn{

    .round(0px);
    text-align: center;
    .transition(0.3s);
    border: 1px solid @light-border-color;
    .drop-shadow(0, 1px, 1px, 0, 0.1);
    background: transparent;
    color: @text-color;
    padding: 6px 12px;

    &:hover{
        border: 1px solid @primary-color;
        color: @primary-color;
        background: transparent;
    }

}

// ERROR NOTIFICATION

#contact-form .p-errors label{
    color: lightcoral;
}

#contact-form .p-errors input[type="text"],
#contact-form .p-errors input[type="email"],
#contact-form .p-errors textarea{
    border-color: lightcoral;
}

// WordPress Theme Support

.wp-caption {
    text-align: center;
}

.wp-caption .wp-caption-text {
    margin-bottom: 0px;
    font-size: 12px;
    font-style: italic;
    line-height: 34px;
}

.sticky{   
    margin-bottom: 21px;
    background: #FFFFFF;    
}

/*----- SECTION OVERLAY  ----*/

.section-theme-bg-overlay{
    position: relative;
    clear: both;
    
    &:before{
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(57,105,247,0.8);
        content: '';
        width: 100%;
        height: 2000px;
    }
}

.section-white-bg-overlay{
    position: relative;
    clear: both;
    
    &:before{
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255,255,255,0.8);
        content: '';
        width: 100%;
        height: 2000px;
    }
}

.section-black-bg-overlay{
    position: relative;
    clear: both;
    
    &:before{
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.8);
        content: '';
        width: 100%;
        height: 2000px;
    }
}

/*------------------------------ Gallery Caption  ---------------------------------*/

.gallery-caption{}

/*------------------------------ By Post Author ---------------------------------*/

.bypostauthor {}


/***********************************************************************************************/
/* 02. HEADER */
/***********************************************************************************************/

.main-header {

    // New
    height: auto;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1000;

    .sticky-wrapper{
        background: rgba(0,0,0,0.4);
    }

    .top-bar {

        background: rgba(0,0,0,0.6);
        line-height:  38px;
        color: @light-text-color;
        font-size: 14px;

        i{
            color: @primary-color;
            margin-right: 5px;
            display: inline-block;
        }

        a{
            color: @light-text-color;
        }

    }

    .top-email-info{
        display: inline-block;
        margin-left: 8px;
        line-height: 41px;
    }

    .top-phone-no{
        display: inline-block;
        margin-left: 18px;
        line-height: 41px;
    }

    .top-ask-question-btn{
        background: @primary-color;
        color:@light-text-color;
        padding: 10px 16px;
        font-size: 15px;
        border: 0px solid #FFF;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: 600;

        .round(0px);

        i.fa{
            color:@light-text-color;
        }

        &:hover{

            color: @primary-color;
            background: darken( @pure-white-bg, 10%);
        }
    }

    .top-location-info{
        margin-right: 12px;
    }

}


// Header Layout 2

.main-header-2 {

    // New

    height: auto;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 1000;

    .logo-container{

        .top_info_box{
            text-align: right;
            padding: 24px 0 24px 24px;
        }

    }

    .sticky-wrapper{
        background: rgba(0,0,0,0.4);
    }

    .header-wrapper-2{
        background: #585c5d;
    }

    .top-bar {

        background: rgba(0,0,0,0.8);
        line-height:  38px;
        color: @light-text-color;
        font-size: 14px;

        i{
            color: @primary-color;
            margin-right: 5px;
            display: inline-block;
        }

        a{
            color: @light-text-color;
        }

    }

    .top-welcome-info{
        display: inline-block;
        margin-left: 0px;
        line-height: 41px;
    }

    .top-email-info{
        display: inline-block;
        margin-left: 8px;
        line-height: 41px;
    }

    .top-phone-no{
        display: inline-block;
        margin-left: 18px;
        line-height: 41px;
    }

    .top-location-info{
        margin-right: 12px;
    }

    .navbar-collapse{
        padding-right: 0px;   
    }

    #jumbotron_1{
        .jumbotron-content {
            top: 35%;  
        }
    }

}

// SITE LOGO

.logo {
    margin-top: 32px;
    margin-bottom: 0;
}

.header-container{
    border-bottom: 0px solid #FFF;
    padding-bottom: 0px; //24px
    box-shadow: 0px 0px 2px -4px rgba(0, 0, 0, 0.2);
    .transition(0.2s);
}

/***********************************************************************************************/
/* 03. MAIN NAVIGATION MENU */
/***********************************************************************************************/

.navbar-header{
    a.logo {
        margin-top: 16px;
        display: inline-block;

        img{
            display: block;
        }
    }
}

.navbar-default {
    border: none;
    background: transparent;
    margin-bottom: 0px;
    padding: 0 !important;


    .navbar-nav {
        margin-top: 10px;
    }
    .navbar-nav > li {
        margin-left: 20px;
    }
    .navbar-nav > li > a {
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        padding-bottom: 22px;
        line-height: 32px;
    }
    .navbar-nav > li > a:hover {
        color: #EEEEEE;
    }
    .navbar-nav li.drop {
        position: relative;
        a{

            &:after{
                content: "\f107";
                font-family: fontawesome;
                position: absolute;
                right: -3px;
                top: 15px;
                font-weight: normal;
            }

        }
        
    }
    .navbar-nav li ul.drop-down {
        margin: 0;
        padding: 0;
        position: absolute;
        top: 69px;
        left: 0;
        width: 160px;
        visibility: hidden;
        opacity: 0;
        z-index: 3;
        text-align: left;
        .transition(all, 0.3s);
    }
    .navbar-nav li ul.drop-down li {
        list-style: none;
        display: block;
        margin: 0;
    }
    .navbar-nav li ul.drop-down li ul.drop-down.level3 {
        -webkit-transform: rotateX(0deg);
        -moz-transform: rotateX(0deg);
        -ms-transform: rotateX(0deg);
        -o-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform: rotateX(-90deg);
        -moz-transform: rotateX(-90deg);
        -ms-transform: rotateX(-90deg);
        -o-transform: rotateX(-90deg);
        transform: rotateX(-90deg);
        top: 0px;
        left: 100%;
        border-bottom: none;
        a{
            
            &:after{
                content: '';
            }
            
        }
    }
    .navbar-nav li ul.drop-down li a {
        display: inline-block;
        text-decoration: none;
        display: block;
        color: @light-text-color;
        font-size: 14px;
        padding: 12px 20px;
        text-transform: uppercase;
        font-weight: 500;
        background: rgba(0,0,0,0.7);
        margin: 0;
        border: none;
        
         &:after{
            content: '';
            font-family: FontAwesome;
            position: absolute;
            right: 12px;
        }
    }
    
    .navbar-nav li ul.drop-down li a:hover {

        background: rgba(0,0,0,0.8);
        color: @light-text-color;
    }
    
    .navbar-nav li ul.drop-down li.drop a {
        display: inline-block;
        text-decoration: none;
        display: block;
        color: @light-text-color;
        font-size: 14px;
        padding: 12px 20px;
        text-transform: uppercase;
        font-weight: 500;
        margin: 0;
        border: none;
        
         &:after{
            content: '\f105';
            font-family: FontAwesome;
            position: absolute;
            right: 12px;
        }
    }
    
    .navbar-nav li ul.drop-down li:hover ul.drop-down.level3 {
        -webkit-transform: rotateX(0deg);
        -moz-transform: rotateX(0deg);
        -ms-transform: rotateX(0deg);
        -o-transform: rotateX(0deg);
        transform: rotateX(0deg);
         a{
            
            &:after{
                content: '';
            }
            
        }
        
    }
    .navbar-nav li ul.drop-down li:last-child {
        border-bottom: none;
    }
    .navbar-nav li:hover > ul.drop-down {
        visibility: visible;
        opacity: 1;
    }

}

header.one-page .navbar-nav > li {
    margin-left: 0;
}
header.one-page .navbar-nav > li > a span {
    color:@primary-color;
}

// STICKY TOP MENU

.stuck {
    position:fixed;
    top:0;    
    left: 0px;
    right: 0px;
    z-index: 99;
    opacity: 1;
    .transition(opacity, 0.3s);
    background: rgba(0,0,0,0.8);
    width: 100%;
    padding: 0px;
    margin: 0;
}


/***********************************************************************************************/
/* 04. HOME PAGE */
/***********************************************************************************************/

// SECTION CONTENT BLOCK.

.section-content-block{
    padding: 80px 0 80px  0;
}

.section-pure-white-bg{
    background: @pure-white-bg;
}

.section-secondary-bg{
    background: @second-bg-color;
}

.section-pure-black-bg{
    background: @pure-black-bg;
}

.section-content-block-30{
    padding: 30px 0 30px  0;
}

// SECTION BANNER

.section-banner{
    padding: 280px 0px 250px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
}

.banner-content{
    
    width: 100%;    

    h2 {
        font-family: @jumbotron-font;
        font-size: 48px;
        line-height: 60px;
        font-weight: 700;
        margin: 0 0 0 0;
        color: @light-text-color;
    }

    h3 {
        font-family: @jumbotron-font;
        color: @light-text-color;
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        margin-top: 0px;
    }

    span.slider-text-separator{
        display: block;
        width: 60px;
        height: 2px;
        background: @primary-color;
        margin: 24px 0;
    }

    a.btn-slider {
        font-size: 16px;
        margin-top: 24px;
        background: @primary-color;
        border:2px solid @primary-color;
        color: @light-text-color;
        padding:10px 20px;
        text-transform:uppercase;
        font-weight: 600;
        text-align: left;
        .round(0px);

        &:hover{
            background: darken(@primary-color, 10%);
            border:2px solid @primary-color;
            color: @light-text-color;
        }

    }

}

// JUMBOTRON 

#jumbotron_1{

    position: relative;
    text-align: center;

    .item img {
        display: block;
        width: 100%;
        height: auto;
    }


    .jumbotron-content {
        position: absolute;
        top: 40%;
        width: 100%;

        h2 {
            font-family: @jumbotron-font;
            font-size: 48px;
            line-height: 60px;
            font-weight: 700;
            letter-spacing: 0.01em;
            color: @light-text-color;

            span{
                background: rgba(64,193,240,0.7);
                padding: 0 5px;

            }
        }

        h3 {

            font-family: @jumbotron-font;
            font-weight: 400;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: 0.02em;
            margin: 24px 0 32px 0;
            color: @light-text-color;

            span{
                background: rgba(64,193,240,0.7);
                padding: 5px 12px;
            }
        }

        a.btn-jumbotron {
            margin-top: 18px;
            background: @primary-color;
            border:2px solid @primary-color;
            color: @light-text-color;
            height: 46px;
            line-height: 30px;
            padding-left: 28px;
            padding-right: 28px;
            letter-spacing: 0.03em;
            font-weight: 600;
            margin-left: 9px;
            .round(0px);

            &:hover{
                border:2px solid @primary-color;
                background: rgba(64,193,240,0.7);
                color: @light-text-color;
            }

        }

        a.btn-jumbotron-2 {
            margin-top: 18px;
            margin-left: 9px;
            background: @pure-white-bg;
            border:2px solid @pure-white-bg;
            color: @primary-color;
            height: 46px;
            line-height: 30px;
            padding-left: 28px;
            padding-right: 28px;
            letter-spacing: 0.03em;
            font-weight: 600;
            .round(0px);

            &:hover{
                border:2px solid @pure-white-bg;
                background: rgba(255,255,255,0.7);
                color: @primary-color;
            }

        }

        a.btn-animated {
            margin-top: 12px;
            background: rgba(255,255,255,0.8);
            border:2px solid transparent;
            box-shadow: 0 0 0 6px rgba(64, 193, 240, 0.5);
            color: lighten(@default-font-color, 10%);
            height: 46px;
            line-height: 30px;
            width: 46px;
            font-weight: 600;
            font-size: 24px;
            -webkit-transition: all 0.8s  ease-out;
            -moz-transition: all 0.8s  ease-out;
            -o-transition: all 0.8s  ease-out;
            transition: all 0.8s  ease-out;
            .round(100%);

            &:hover{
                border:2px solid transparent;
                background: rgba(255,255,255,0.05);
                color: @primary-color;
            }

        }

    }

}


.home-page-section-heading{

    span{
        font-size: 16px;
        font-weight: normal;
        font-style: italic;
        color: lighten(@dark-text-color, 30%);
    }

}

.btn-heading{
    background: transparent;
    border: 2px solid @primary-color;
    color: @primary-color;
}

/*------ KNOWLEDGE BASE SEARCH BOX --------*/

form.bkb-live-search-form{
    margin-bottom: 24px;
    position: relative;
}

p.example-box{
    text-align: center;
    margin-top: 12px;
    color: @primary-color;
    font-weight: 400;

    span{
        font-weight: 600;
    }

    a{
        display: inline-block;
        color: @light-text-color;

    }
}

div.remodal form.bkb-live-search-form{
    margin-bottom: 0px;
    position: relative;
}

div.remodal span.bkbm-btn-clear{
    background: #f4f4f4;
}

#result {
    height:20px;
    font-size:16px;
    font-family:Arial, Helvetica, sans-serif;
    color:#333;
    padding:5px;
    margin-bottom:10px;
    background-color:#FFFF99;
}

form.bkb-live-search-form #s{
    display: block;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    border: 1px solid lightgray;
    height: 3em;
    padding: 12px;
    box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.2);
}

.bkbm-dn{
    display: none;
}

span.bkbm-btn-clear{
    position: absolute;
    top: 3px;
    width: 30px;
    height: 2.5em;
    line-height: 2.5em;
    text-align: center;
    background: #FFFFFF;
    cursor: pointer;
}

span.bkbm-btn-clear:after{
    position: absolute;
    content: 'X';
    display: inline-block;
    top: -1px;
    left: 8px;
    font-weight: bold;
    font-size: 14px;
    color: #6a6a6a;
    font-family: verdana;
    -webkit-transition: all 0.5s  ease-out;
    -moz-transition: all 0.5s  ease-out;
    -o-transition: all 0.5s  ease-out;
    transition: all 0.5s  ease-out;
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    transform: rotateY(0deg);
}

span.bkbm-btn-clear:hover:after{
    -webkit-transform: rotateY(360deg);
    -moz-transform: rotateY(360deg);
    -ms-transform: rotateY(360deg);
    -o-transform: rotateY(360deg);
    transform: rotateY(360deg);
}

span.bkbm-btn-clear:hover{
    color: #2C2C2C;
}

.suggestionsBox {
    position: absolute;
    left: 0px;
    top:50px;
    width: 100%;
    padding:0px;
    background-color: #2c2c2c;
    /*color: #fff;*/
    z-index: 1000;
}


div.suggestionList ul{
    margin: 0px;
    padding: 0px;
}
div.suggestionList:before{

    content: "";
    display: inline-block;
    height: 0;
    margin-right: 0.5em;
    width: 0;
    left: 10px;
    margin-top: -0.5em;
    position: absolute;
    top:1px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #2c2c2c;
    z-index: 999;
}
div.suggestionList ul li {
    list-style:none;
    margin: 0px;
    border-bottom:1px solid #CCCCCC;
    cursor: pointer;
    font-size: 12px;
    text-align: left;
    color:#FFFFFF;
}

div.suggestionList ul li a{
    text-decoration: none;
    color: blue;
    /*color: #FFFFFF;*/
    padding: 5px;
    display: block;
}

div.suggestionList ul li:last-child{
    border-bottom: 0px;
}

div.suggestionList ul li a:hover,
div.suggestionList ul li a:visited {

    color:#F3F3F3;
}

.nothing-found{
    padding: 5px;
    color: #FFFFFFF;
}


.search_icon{
    background-origin: content-box;
    background-image: url(../images/search.png);
    background-repeat:  no-repeat;
    background-position: top 10px right 10px;
    background-position-x: 99%;
    background-position-y: 50%;
    border: 1px solid #cccccc;
    background-color: #FFFFFF;
}

.load{
    background-origin: content-box;
    background-image: url(../images/loader.gif);
    background-repeat:  no-repeat;
    background-position: top 10px right 10px;
    background-position-x: 99%;
    background-position-y: 50%;   
    background-color: #FFFFFF;
}

#suggest {
    position:relative;
}


/*------ KB QUESTION FORM --------*/

.faq-ask-question-section{

    padding: 16px 0 32px;
    background-image: url("../images/faq_ask_question_bg.jpg");
    background-repeat: no-repeat;
    background-position: 0 70%;
    background-size: cover;
    background-attachment: fixed;
    position: relative ;
    overflow: hidden;

    &:before{
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255,255,255,0.9);
        content: '';
        width: 100%;
        height: 2000px;
    }

}

.ask-question-section{

    .ask-question-image-block{
        background: transparent;
        background-image: url('../images/ask_question_feat_img.jpg');
        background-repeat: no-repeat;
        background-position: center left;
        background-size: cover;
        height: 655px;
    }

    .ask-question-image-block-2{
        background: transparent;
        background-image: url('../images/ask_question_feat_img_2.jpg');
        background-repeat: no-repeat;
        background-position: center left;
        background-size: cover;
        height: 655px;
    }

    .ask-question-block{

        padding: 42px 0 24px 42px;

        h2{
            position: relative;
            font-family: @heading-font-alt;
            text-transform: uppercase;
            font-size: 24px;
            line-height: 42px;
            font-family: @heading-font-alt;
            letter-spacing: 0.03em;
            font-weight: 700;
            color: lighten(@heading-font-color, 5%);

            &:after{

                .transition(all, 0.3s);
                position: absolute;
                content: '';
                bottom: 0;
                left: 0;
                display: block;
                width: 60px;
                height: 2px;
                background: @primary-color;

            }

            span{
                font-weight: 400;
                font-style: normal;
                color: lighten(@heading-font-color, 0%);

            }

        }

        h3{
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
            margin-bottom: 24px;
            letter-spacing: 0.02em;
            color: lighten(@heading-font-color, 10%);
            span{
                color:@primary-color;
                font-weight: 600;
            }
        }

        .progress-bar {
            background: @primary-color;
        }


        a.btn-donation {
            margin-top: 32px;
            background: @primary-color;
            border:2px solid @primary-color;
            color: @light-text-color;
            height: 46px;
            line-height: 30px;
            padding-left: 28px;
            padding-right: 28px;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0.02em;
            .round(0px);

            &:hover{
                border:2px solid @primary-color;
                background: rgba(235,70,74,0.7);
                color: @light-text-color;
            }

        }


    }
}

.home-faq-container{
    margin-top: 54px;
}

h2.only-heading{
    position: relative;
    font-family: @heading-font-alt;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 42px;
    font-family: @heading-font-alt;
    letter-spacing: 0.03em;
    font-weight: 700;
    color: lighten(@heading-font-color, 5%);

    &:after{

        .transition(all, 0.3s);
        position: absolute;
        content: '';
        bottom: -8px;
        left: 0;
        display: block;
        width: 60px;
        height: 2px;
        background: @primary-color;

    }

}

#home_faqs{
    margin-top: 36px;
}

.bkb-ques-form-container{
    width: 500px;
    max-width: 100%;
    margin-top: 11px;
    clear: both;
    display: block;
    text-align: left;

    h2{
        font-size: 18px;
        margin: 12px 0px 24px;
        padding: 0;
        padding-bottom: 12px;
    }

    .bkb-ques-form-message-box{
        width: 100%;
        border:1px solid #EEEEEE;
        line-height: 2.4em;
        text-indent: 2px;
        display: none;
        margin-bottom: 11px;
        box-sizing: border-box;
        padding: 5px 0 5px 10px;
    }

    form {

        p{
            display: block;
            clear: both;
            margin: 0 0 16px 0;
        }

        input[type=text] {
            width: 100%;
            margin-bottom: 0px;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            -o-border-radius: 0px;
            border-radius: 0px;
            border: 1px solid lightgray;
            height: 42px;
            line-height: 24px;
            box-sizing: border-box;
            padding: 0 12px;
        }

        textarea {
            width: 100%;
            margin-bottom: 0px;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            -o-border-radius: 0px;
            border-radius: 0px;
            border: 1px solid lightgray;
            line-height: 24px;
            min-height: 120px;
            box-sizing: border-box;
            padding: 12px;
        }

        select {    
            margin-bottom: 0px;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            -o-border-radius: 0px;
            border-radius: 0px;
            border: 1px solid lightgray;
            width: 100%;
            height: 42px;
            line-height: 32px;
            padding: 6px;
        }


        input[type=submit]{
            margin-top: 12px;
            background: @primary-color;
            border:2px solid @primary-color;
            color: @light-text-color;
            height: 46px;
            line-height: 30px;
            padding-left: 32px;
            padding-right: 32px;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            .round(0px);

            &:hover{
                border:2px solid @primary-color;
                background: rgba(64,193,240,0.7);
                color: @light-text-color;
            }

        }


        input.bkb_ques_disabled_field,
        select.bkb_ques_disabled_field,
        nput[type=submit].bkb_ques_disabled_field{
            background: #EEEEEE;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            -o-border-radius: 0px;
            border-radius: 0px;
            border: 1px solid lightgray;
            height: 32px;
            line-height: 24px;
        }

        input.captcha, 
        input.sum {
            display: inline;
            text-align: center;
            width: 40px;
        }

        input.captcha{
            width: 60px;
        }

        span#spambot {
            font-size: 11px;
        }

    }

}

.bkb-ques-form-success-box{
    background: #8ad088;
    color: #FFFFFF;
}

.bkb-ques-form-wait-box{
    background: #ffffc1;
    color: #000000;
}

.bkb-ques-form-error-box{
    background: #e63f37;
    color: #FFFFFF;
}


p.bkb_question_submit_container{
    text-align: left;
}

// Counter Block.

.counter-section-1{

    padding: 0px;
}

.counter-layout-9{

    border: 1px solid @light-border-color;
    background: #F9FAFB;
    border-top: 0px;
    padding: 20px;

    i{
        float: left;
        font-size: 72px;
        display: none;
        margin: 12px 12px 0 0;
        color: lighten(@dark-text-color, 80%);

    }

    span.counter{
        display: block;
        font-size: 46px;
        font-weight: 300;
        font-family: @heading-font-alt;
        line-height: 46px;
        margin: 5px 0;
        position: relative;
        text-align: center;
        left: -11px;
    }

    span.counter:after{
        position: absolute;
        content: '+';
        font-size: 48px;
        display: inline-block;
        margin-left: 5px;
        font-family: @heading-font-alt;
        font-weight: 300;
        color: lighten(@dark-text-color, 50%);

    }

    span.counter_text{
        color: #eb464a;
        display: block;
        font-family: @heading-font-alt;
        font-size: 18px;
        letter-spacing: 0.1em;
        line-height: 32px;
        text-transform: uppercase;
        text-align: center;
    }

    &:first-child{
        border-right: 0px;
    }

    &:nth-child(2){
        border-right: 0px;
    }

    &:nth-child(3){
        border-right: 0px;
    }



}


//CTA.

.cta-section-1{
    padding: 64px 0;
    background: @dark-bg;
    text-align: center;

    h2{
        font-size: 36px;
        line-height: 48px;
        color: @light-text-color;
        margin: 0;
    }

    p{
        font-size: 20px;
        color: @light-text-color;
        line-height: 28px;
    }

    a.btn{
        margin-top: 16px;
        background: @primary-color;
        color: @light-text-color;
        border: 1px solid @primary-color;
        padding: 12px 24px;
        font-size: 18px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        .round(0);

        &:hover{

            background: transparent;
            color: @light-text-color;
            border: 1px solid @light-border-color;
        }

    }
}

.cta-section-2{

    padding: 100px 0;
    background: @pure-black-bg;

    h2{
        font-size: 24px;
        line-height: 42px;
        color: @light-text-color;
        margin: 0;
        font-family: @heading-font-alt;
        font-weight: 400;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        display: inline-block;
        text-align: left;
    }

    a.btn{
        margin-top: 12px;
        background: transparent;
        color: @light-text-color;
        border: 1px solid @light-border-color;
        padding: 12px 24px;
        font-size: 18px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        .round(0);

        &:hover{

            background: @primary-color;
            color: @light-text-color;
            border: 1px solid @primary-color;

        }

    }

}

.cta-section-3{
    padding: 64px 0;
    background: transparent;
    background-image: url('../images/pattern_1.png');
    background-repeat: repeat;
    background-position: center center;
    background-size: initial;
    background-attachment: fixed;
    display: block;
    clear:both;
    position: relative;
    overflow: hidden;
    text-align: center;

    &:before{
        position: absolute;
        width: 100%;
        height: 100%;
        background: @primary-color;
        opacity: 0.95;
        top: 0;
        left: 0;
        content: '';
    }



    h2{
        font-size: 26px;
        line-height: 42px;
        color:@light-text-color;
        font-weight: 300;
        margin: 0;
        font-family: @heading-font-alt;
        letter-spacing: 0.01em;
    }
    p{
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.004em;
        color: darken(@light-text-color, 5%);
    }

    a{
        display: block;
        margin: 24px auto 0 auto;
        width: 180px;
        color: @primary-color;
        background: @pure-white-bg;
        line-height: 32px;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.02em;
        .round(0px);
    }
}

.about-us-section-2{

    h2{
        font-size: 42px;
        line-height: 48px;
        margin-bottom: 32px;
        font-family: @heading-font-alt;
        font-weight: 400;
    }



    .about_issue_header{
        position: relative;
        font-weight: 500;
        font-size: 32px;
        i.fa{
            color: @primary-color;
            font-size: 60px;
            top: 0;
            left: 0;
            display: block;
            margin-bottom: 12px;
        }
    }

    .about_slogan_text{
        font-size: 21px;
        color: #626262;
        font-style: normal;
        font-weight: 300;
        line-height: 28px;
        margin-top: 24px;
    }

    .activity_box{

        padding: 0 ;

        span.fa{
            display: none;
        }

        h4{

            position: relative;
            font-family: @heading-font-alt;
            color: lighten(@default-font-color, 5%);
            margin-bottom: 0px;
            font-weight: 500;

            &:after{

                .transition(all, 0.3s);
                position: absolute;
                content: '';
                bottom: -8px;
                left: 0;
                display: block;
                width: 24px;
                height: 2px;
                background: @light-border-color;

            }

        }

        p{
            color: lighten(@default-font-color, 17%);
            margin-bottom: 24px;
        }


        &:hover{

            h4{

                &:after{
                    background: @primary-color;
                    width: 60px;
                }

            }

        }

    }

    .video_icon_block,
    .about_quote_1_block,
    .about_quote_2_block,
    .about_info_block{
        padding:  12px 24px 24px;
    }

    .video_icon_block i{
        color: @primary-color;
        font-size: 120px;
    }

    .about_quote_2_block p{
        font-weight: 600;
        font-style: italic;
        color:@default-font-color;
    }

    .about_info_block{

        i{
            display: block;
            color: @primary-color;
            font-size: 32px;
            line-height: 32px;
        }
        span.counter{
            display: block;
            font-size: 72px;
            line-height: 72px;
        }

        span.campaign{
            display: block;
            color: @primary-color;
            font-size: 24px;
            line-height: 32px;
        }
    }

}


.counter-layout-2{

    border-top: 0px;
    padding: 24px;

    i{
        float: left;
        font-size: 72px;
        display: inline-block;
        margin: 12px 12px 0 0;
        color: lighten(@dark-text-color, 80%);
    }

    span.counter{
        font-size: 48px;
        display: block;
        margin: 16px 0;
        font-weight: 600;
        position: relative;
    }

    span.counter:after{
        position: absolute;
        content: '+';
        font-size: 48px;
        display: inline-block;

    }

    span.counter_text{
        font-size: 18px;
        display: block;
        text-indent: 2px;
        color: @primary-color;
    }


}


// SECTION TESTIMONIAL BLOCK

.testimonial-block{

    background: @primary-color;
    padding:42px;

    h4{
        font-size: 30px;
        line-height: 36px;
        color: @light-text-color;
    }

    p{
        color: @light-text-color;
    }

}

// NEWS BLOCK

.section-news-block{

    .news-wrapper{

        background: @pure-white-bg;
        border: 1px solid @light-border-color;

        .news-img{

            display: block;
            position: relative;
            overflow: hidden;

            img{
                margin-bottom: 0px;
                width:100%;
                display: block;
            }

            .date-meta{
                position: absolute;
                left: 0;
                bottom: 0;
                display: inline-block;
                width: 60px;
                background: @primary-color;
                font-size: 30px;
                width: 110px;
                text-align: center;
                color: @light-text-color;
                padding: 6px 0;
                font-weight: 600;

                span{
                    font-size: 16px;
                    display: block;
                }
            }

        }

        .news-meta{
            list-style: none;
            overflow: hidden;

            li{
                display: block;
            }
            margin: 6px 0;
        }

        .news-button{
            border-radius: 0px;
            border: 0px;
            transition: all 0.4s ease 0s;
            background: @primary-color;
            font-size: 14px;
            color: @light-text-color;

            &:hover{
                background:#FFFFFF;
                border: 0px;
                color: @primary-color;

            }

        }

        .news-content{

            padding: 0px 12px 24px 12px;

            .news-text{

                h5{

                    a{
                        font-family: @heading-font-alt;
                        margin-top: 32px;
                        color: #444444;
                        font-size: 18px;
                        line-height: 32px;
                        display: block;
                        text-transform: capitalize;
                        font-weight: 400;
                    }
                }

                p{
                    font-size: 16px; 
                    font-weight: normal; 
                }

            }


        }

        // Hover Elements.

        &:hover{

            .news-img{

                &:before{
                    width: 100%;
                    background: rgba(0,0,0,0.5);
                }
            }

            .news-button{
                background: @primary-color;
                color: #FFFFFF;

                .fa{
                    color: #FFFFFF;

                }

            }
        }

    }


}

//SECTION SHARE

.btn-social-icon {
    height: 42px;
    width: 42px;
    border: 0;
    border-radius: 0px;
    margin: 3px 3px;
}

.btn-social-icon .fa{
    line-height: 42px;
    color: #ffffff;
}

.btn-goggle-plus {
    background-color: #26b84b;
}

.btn-goggle-plus:hover {
    background-color: #20993e;
}

.btn-envelope-o {
    background-color: #d74b3e;
}

.btn-envelope-o:hover {
    background-color: #ad3d32;
}


//CONTACT SECTION.

.section-contact-block{

    position: relative;
    overflow: hidden;

    .content-block{
        padding:  0 48px 0 0;
    }

    .contact-title{
        font-size: 24px;
        border-bottom: 3px solid #eeeeee;
        color: #5c5c5c;
        padding-bottom: 15px;
        position: relative;

        &:after {
            bottom: -3px;
            content: "";
            height: 3px;
            left: 0;
            position: absolute;
            width: 32px;
            background: @primary-color;
        }

    }


    .contact-info{

        li{
            margin-bottom: 24px;
        }

        .icon-container{
            display: inline-block;
            background: @primary-color;
            color: @light-text-color;
            width: 32px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            margin-right: 16px;

            i{
                font-size: 14px;
            }
        }

        address{
            display: inline-block;
        }

        a{

            color: @default-font-color;

            &:hover{
                color: @primary-color;
            }

        }
    }

    // Contact Form

    .contact-form-block{

        padding: 0 0 0 0px;

    }

    #contact-form{

        input[type=text],
        input[type=email],
        input[type=url],
        input[type=password]{
            border: 0px;
            border-bottom: 1px solid @border-color;
            line-height: 42px;
            height: 42px;
            margin-bottom: 24px;
            padding-left: 2px;
            .round(0);
            .drop-shadow(0,0,0,0);
            .inner-shadow(0,0,0,0);
            .transition( 0.3s);
            &:focus{

                .drop-shadow(0,0,0,0);
                .inner-shadow(0,0,0,0);
                border: 0px;
                border-bottom: 1px solid lighten(@dark-border-color,50%);

            }

        }


        textarea{
            border: 0px;
            border-bottom: 1px solid @border-color;
            padding-left: 2px;
            margin-bottom: 24px;
            .round(0);
            .drop-shadow(0,0,0,0);
            .inner-shadow(0,0,0,0);
            .transition( 0.3s);
            &:focus{

                .drop-shadow(0,0,0,0);
                .inner-shadow(0,0,0,0);
                border: 0px;
                border-bottom: 1px solid lighten(@dark-border-color,50%);

            }

        }

    }


}

/***********************************************************************************************/
/* MAIN CONTENT */
/***********************************************************************************************/

.main-content{
    margin: 48px 0;
}


/***********************************************************************************************/
/* 05. FORUM LAYOUT */
/***********************************************************************************************/


#bbpress-forums {
    background: transparent;
    clear: both;
    font: 16px/28px @body-font;
    margin-bottom: 20px;
    overflow: hidden;

    div.even, 
    ul.even {
        background-color: #fff;
    }
    div.odd, 
    ul.odd {
        background-color: #fbfbfb;
    }

}

body.reply-edit .reply {
    float: none;
}
#bbpress-forums div.reply {
    height: auto;
    width: 100%;
}
#bbpress-forums div.bbp-forum-header, 
#bbpress-forums div.bbp-topic-header, 
#bbpress-forums div.bbp-reply-header {
    background-color: #fafafa;
}
#bbpress-forums .status-trash.even, 
#bbpress-forums .status-spam.even {
    background-color: #fee;
}
#bbpress-forums .status-trash.odd, 
#bbpress-forums .status-spam.odd {
    background-color: #fdd;
}
#bbpress-forums .status-closed, #bbpress-forums .status-closed a {
    color: #ccc;
}
#bbpress-forums ul {
    background: rgba(0, 0, 0, 0);
    list-style: outside none none;
    margin: 0;
    padding: 0;
}
#bbpress-forums ul.bbp-threaded-replies {
    margin-left: 50px;
}
#bbpress-forums li {
    background: rgba(0, 0, 0, 0);
    list-style: outside none none;
    margin: 0;
}
#bbpress-forums ul.bbp-lead-topic, #bbpress-forums ul.bbp-topics, #bbpress-forums ul.bbp-forums, #bbpress-forums ul.bbp-replies, #bbpress-forums ul.bbp-search-results {
    border: 1px solid #eee;
    clear: both;
    margin-bottom: 20px;
    overflow: hidden;
}
#bbpress-forums li.bbp-header, 
#bbpress-forums li.bbp-body, 
#bbpress-forums li.bbp-footer {
    clear: both;
}
#bbpress-forums li.bbp-header, 
#bbpress-forums li.bbp-footer {
    background:@blog-bg-color;
    border-top: 1px solid #eee;
    font-weight: bold;
    padding: 8px;
    text-align: center;
}
#bbpress-forums li.bbp-header {
    background:@blog-bg-color;

    border-top: 0px solid #eee;
}
#bbpress-forums li.bbp-header ul {
    overflow: hidden;
}
#bbpress-forums .bbp-forums-list {
    border-left: 1px solid #ddd;
    margin: 0 0 0 5px;
    padding-left: 15px;
}
#bbpress-forums .bbp-forums-list li {
    display: inline;
}
#bbpress-forums li.bbp-footer p {
    line-height: 1em;
    margin: 0;
}
li.bbp-forum-info, li.bbp-topic-title {
    float: left;
    text-align: left;
    width: 55%;
}
li.bbp-forum-topic-count, li.bbp-topic-voice-count, li.bbp-forum-reply-count, li.bbp-topic-reply-count {
    float: left;
    text-align: center;
    width: 10%;
}
li.bbp-forum-freshness, li.bbp-topic-freshness {
    float: left;
    text-align: center;
    width: 22%;
}
#bbpress-forums li.bbp-body ul.forum, #bbpress-forums li.bbp-body ul.topic {
    border-top: 1px solid #eee;
    overflow: hidden;
    padding: 8px;
}
li.bbp-header div.bbp-topic-content span#subscription-toggle,
li.bbp-header div.bbp-topic-content span#favorite-toggle,
li.bbp-header div.bbp-reply-content span#subscription-toggle,
li.bbp-header div.bbp-reply-content span#favorite-toggle {
    float: right;
}
#bbpress-forums div.bbp-forum-title h3, #bbpress-forums div.bbp-topic-title h3, #bbpress-forums div.bbp-reply-title h3 {
    background: rgba(0, 0, 0, 0);
    border: medium none;
    font-size: 16px;
    line-height: 1em;
    margin: 8px 0;
    padding: 0;
    text-transform: none;
}
#bbpress-forums div.bbp-forum-author, #bbpress-forums div.bbp-topic-author, #bbpress-forums div.bbp-reply-author {
    float: left;
    text-align: center;
    width: 115px;
}
#bbpress-forums div.bbp-forum-author img.avatar, #bbpress-forums div.bbp-topic-author img.avatar, #bbpress-forums div.bbp-reply-author img.avatar {
    border: medium none;
    float: none;
    margin: 12px auto 0;
    max-width: 80px;
    padding: 0;
}
#bbpress-forums div.bbp-forum-author a.bbp-author-name, #bbpress-forums div.bbp-topic-author a.bbp-author-name, #bbpress-forums div.bbp-reply-author a.bbp-author-name {
    display: inline-block;
    margin: 0 12px;
}
#bbpress-forums div.bbp-topic-author a.bbp-author-name, #bbpress-forums div.bbp-reply-author a.bbp-author-name {
    clear: left;
    display: block;
}
#bbpress-forums div.bbp-forum-author .bbp-author-role, #bbpress-forums div.bbp-topic-author .bbp-author-role, #bbpress-forums div.bbp-reply-author .bbp-author-role {
    font-size: 11px;
    font-style: italic;
}
#bbpress-forums li.bbp-header .bbp-search-author, #bbpress-forums li.bbp-footer .bbp-search-author, 
#bbpress-forums li.bbp-header .bbp-forum-author, #bbpress-forums li.bbp-footer .bbp-forum-author, 
#bbpress-forums li.bbp-header .bbp-topic-author, #bbpress-forums li.bbp-footer .bbp-topic-author, 
#bbpress-forums li.bbp-header .bbp-reply-author, #bbpress-forums li.bbp-footer .bbp-reply-author {
    float: left;
    margin: 0;
    padding: 0;
    width: 120px;
}
#bbpress-forums li.bbp-header .bbp-search-content, 
#bbpress-forums li.bbp-footer .bbp-search-content, 
#bbpress-forums li.bbp-header .bbp-forum-content, #bbpress-forums li.bbp-footer .bbp-forum-content, 
#bbpress-forums li.bbp-header .bbp-topic-content, #bbpress-forums li.bbp-footer .bbp-topic-content, 
#bbpress-forums li.bbp-header .bbp-reply-content, #bbpress-forums li.bbp-footer .bbp-reply-content {
    margin-left: 140px;
    padding: 0;
    text-align: left;
}
div.bbp-forum-header, div.bbp-topic-header, div.bbp-reply-header, li.bbp-body div.hentry {
    margin-bottom: 0;
    overflow: hidden;
    padding: 8px;
}
div.bbp-forum-header, div.bbp-topic-header, div.bbp-reply-header {
    border-top: 1px solid #ddd;
    clear: both;
}
span.bbp-author-ip {
    color: #aaa;
    font-size: 11px;
    font-weight: bold;
}
#bbpress-forums div.bbp-forum-content, 
#bbpress-forums div.bbp-topic-content, 
#bbpress-forums div.bbp-reply-content {
    margin-left: 0px;
    padding: 12px 12px 12px 0;
    text-align: left;
}
#bbpress-forums div.bbp-forum-content::after, 
#bbpress-forums div.bbp-topic-content::after, #bbpress-forums div.bbp-reply-content::after {
    clear: both;
    content: ".";
    display: block;
    float: none;
    font-size: 0;
    height: 0;
    visibility: hidden;
}
#bbpress-forums div.bbp-topic-content a, #bbpress-forums div.bbp-reply-content a {
    background: rgba(0, 0, 0, 0);
    border: medium none;
    display: inline;
    font-weight: normal;
    margin: 0;
    padding: 0;
}
#bbpress-forums div.bbp-topic-content h1, #bbpress-forums div.bbp-reply-content h1, 
#bbpress-forums div.bbp-topic-content h2, #bbpress-forums div.bbp-reply-content h2, 
#bbpress-forums div.bbp-topic-content h3, #bbpress-forums div.bbp-reply-content h3, 
#bbpress-forums div.bbp-topic-content h4, #bbpress-forums div.bbp-reply-content h4, 
#bbpress-forums div.bbp-topic-content h5, #bbpress-forums div.bbp-reply-content h5, 
#bbpress-forums div.bbp-topic-content h6, #bbpress-forums div.bbp-reply-content h6 {
    clear: none;
    line-height: 1em;
    margin: 24px 0;
    padding: 0;
}
#bbpress-forums div.bbp-topic-content img, #bbpress-forums div.bbp-reply-content img {
    max-width: 100%;
}
#bbpress-forums div.bbp-topic-content ul, #bbpress-forums div.bbp-reply-content ul, 
#bbpress-forums div.bbp-topic-content ol, #bbpress-forums div.bbp-reply-content ol {
    margin: 0 15px 15px;
    padding: 0;
}
#bbpress-forums div.bbp-topic-content ul li, #bbpress-forums div.bbp-reply-content ul li {
    list-style-type: disc;
}
#bbpress-forums div.bbp-topic-content ol li, #bbpress-forums div.bbp-reply-content ol li {
    list-style-type: decimal;
}
#bbpress-forums div.bbp-topic-content ol li li, #bbpress-forums div.bbp-reply-content ol li li {
    list-style-type: lower-alpha;
}
#bbpress-forums div.bbp-topic-content ol li li li, #bbpress-forums div.bbp-reply-content ol li li li {
    list-style-type: upper-roman;
}
#bbpress-forums div.bbp-topic-content code, 
#bbpress-forums div.bbp-reply-content code, 
#bbpress-forums div.bbp-topic-content pre, 
#bbpress-forums div.bbp-reply-content pre {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    display: inline-block;
    font-family: Inconsolata,Consolas,Monaco,Lucida Console,monospace;
    margin-top: -3px;
    max-width: 95%;
    padding: 0 5px;
    vertical-align: middle;
}
#bbpress-forums div.bbp-topic-content pre, 
#bbpress-forums div.bbp-reply-content pre {
    display: block;
    line-height: 18px;
    margin: 0 0 24px;
    overflow: auto;
    padding: 5px 10px;
    white-space: pre;
}
#bbpress-forums div.bbp-topic-content pre code, 
#bbpress-forums div.bbp-reply-content pre code {
    background-color: transparent;
    border: medium none;
    display: block;
    margin: 0;
    max-width: 100%;
    overflow: auto;
    overflow-wrap: normal;
    padding: 0;
}
#bbpress-forums div.bbp-reply-to {
    margin-left: 130px;
    padding: 12px 12px 12px 0;
    text-align: right;
}
#bbpress-forums div#bbp-cancel-reply-to {
    text-align: right;
}
div.bbp-breadcrumb {
    float: left;
}
div.bbp-breadcrumb, div.bbp-topic-tags {
    font-size: 12px;
}
#bbpress-forums div.bbp-breadcrumb p, 
#bbpress-forums div.bbp-topic-tags p {
    margin-bottom: 10px;
}
#bbpress-forums div.bbp-topic-tags {
    float: right;
}
#bbp-topic-hot-tags {
    clear: both;
}
#bbpress-forums #bbp-search-form {
    clear: left;
}
#bbpress-forums #bbp-search-form .hidden {
    background: rgba(0, 0, 0, 0);
    height: 0;
    left: -999em;
    overflow: hidden;
    position: absolute;
    width: 0;
}
#bbpress-forums #bbp-search-form #bbp_search {
    display: inline-block;
    width: auto;
}
#bbpress-forums div.bbp-search-form {
    float: right;
}

// Single Forum.

div.bbp-template-notice.info {
    background-color: #f0f8ff;
    border: 1px solid #cee1ef;
}
div.bbp-template-notice, div.indicator-hint {
    background-color: #ffffe0;
    border-color: #e6db55;
    border-style: solid;
    border-width: 1px;
    clear: both;
    color: #000;
    margin: 5px 0 15px;
    padding: 12px 12px 0px;
}

.bbp-pagination-count{
    margin-bottom: 12px;
}

.bbp-reply-header{
    background: #fafafa;
}

/***********************************************************************************************/
/* 06. FAQ PAGE */
/***********************************************************************************************/

.bwl_acc_container .acc_container{
    padding-top: 24px;
}

.bwl_acc_container .acc_container h3{
    font-size: 24px;
}

h2.theme-blue-title-bar a{
    line-height: 46px;
}

.acc-ctrl-btn .acc-expand-all, .acc-ctrl-btn .acc-collapsible-all{
    line-height: 24px;
}

.accordion_search_container .acc_clear_btn::after{
    top: 8px;
}

#bsa_page_navigation{
    margin-top: 24px;

    a,
    a.next_link,
    a.previous_link{
        padding: 8px 14px;
    }
}

#bsa_page_navigation span.total_pages{
    margin: 16px 0 0;
    text-transform: uppercase;
}


/***********************************************************************************************/
/* 07. KNOWLEDGEBASE SINGLE PAGE */
/***********************************************************************************************/

.bkb_container{
    width: 100%;
    border-bottom: #e6e6e6 1px solid; 
    border-top: #e6e6e6 1px solid;
    display: block;
    float: left;
    margin: 12px 0;
    padding: 5px 0;
    clear:both;
}

.bkb_btn_container{


    float: left;
    width: 68%;

}

.bkb_btn_container img.pvm-custom-icon{
    width: 16px;
    height: 16px;
}

p.voting-closed-message{
    font-family: inherit;
    margin:0px 0px;
    padding: 0px;
    line-height: 24px;
}

.msg_container{
    margin-top: 24px;
    text-align: left;
}

.bkb_btn_container .btn_like, 
.bkb_btn_container .btn_dislike{
    width: auto; 
    height: 48px;
    line-height: 48px;
    padding: 0 24px; 
    float: left;  
    margin-top: 15px;
    cursor: pointer; color: #333333; 
    border:#f0f0f0 1px solid;
    margin-right: 5px;
    font-size: 20px;
}

.bkb_btn_container .btn_like{text-align: center; color: #559900;}

.bkb_btn_container .btn_like:hover{

    background-color: #f0f0f0;	border: #c6c6c6 1px solid; 
}
.bkb_btn_container .btn_dislike{ color: #c9231a;}
.bkb_btn_container .btn_dislike:hover, .bkb_btn_container .dislike-h{
    background-color: #f0f0f0;	border: #c6c6c6 1px solid; 
}

.bkb_container .stat-cnt{ float: right; width: 25%;}
.bkb_container .stat-bar{ width: 100%; height: 8px; margin: 12px 0; border: 1px solid #eeeeee;}
.bkb_container .bg-green{ background-color: #559900;}
.bkb_container .bg-red{background-color: #c9231a; }
.bkb_container .total-vote-counter{ font-size: 14px; color: #333; text-align: right; }
.bkb_container .stat-bar div{height: 8px; float: left;}

.bkb_container .like-count-container, 
.bkb_container .dislike-count-container{
    width: auto; height: 18px; 
    float: right; 
    font-size: 12px; line-height: 18px; color: #666;
    padding-left: 15px;
}

/***********************************************************************************************/
/* 08. BLOG LAYOUT */
/***********************************************************************************************/


// Page Header & Breadcrumb 
.page-header{
    margin: 0;
    padding: 200px 0 80px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background-attachment: scroll;
    background-color: transparent;
    background-image: url('../images/bg-geometry.png');
    background-repeat: repeat;
    background-size: initial;
    border: 0px solid #FFF;

    &:before{
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        top: 0;
        left: 0;
        content: '';
        z-index: -1;
    }

    h3{
        color: @light-text-color;
        text-align: center;
        font-size: 36px;
        line-height: 24px;
        font-weight: 500;
        font-family: @heading-font-alt;
        margin: 24px 0;
        text-transform: uppercase;
        letter-spacing: 0.02em;
    }

    .page-breadcrumb{
        font-size: 16px;
        color: @light-text-color;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.01em;

        a{

            color: @light-text-color;

            &:hover{
                color: @primary-color;
            }
        }
    }

}

.single-post{

    margin-bottom: 50px;

    p{
        line-height: 30px;
    }

    .single-post-content{
        margin-bottom: 30px;
        padding: 6px;
        background: @blog-bg-color;

        a{

            img{
                display: block;
            }

        }
    }

    .single-post-title{
        line-height: 24px;

        h2{
            font-size: 24px;
            line-height: 30px;
            text-transform: capitalize;
            letter-spacing: 0.003em;
        }

        .single-post-meta{
            font-size: 16px;
        }
    }

}

.readmore{
    border:0px;
    background: @primary-color;
    color: @light-text-color;
    .round(0px);
    padding: 8px 24px;
    text-transform: uppercase;
    margin-top: 12px;
}

.readmore-sm{
    border:0px;
    background: @primary-color;
    color: @light-text-color;
    .round(0px);
    padding: 6px 12px;
}

/***********************************************************************************************/
/* 09. BLOG SINGLE PAGE */
/***********************************************************************************************/

.single-post-inner{
    background: @blog-bg-color;
    padding: 30px;
}

.post-inner-featured-content{
    display: block;

    img{
        display: block;
        width: 100%;
        text-align: center;
    }
}

.single-post-inner-title{

    h2{
        font-size: 24px;
        margin: 32px 0 24px 0;
    }
    margin-bottom: 24px;
}

.single-post-inner-meta{

    h2{
        font-size: 24px;
    }

    .tag-list{
        a{
            display: inline-block;
            padding: 4px 12px;
            margin-right: 3px;
            background: @primary-color;
            color: @light-text-color;
        }
    }
}



.custom-post-info{margin:0; padding:0;}

.custom-post-thumb-small{width: 70px; height: 70px; }
.custom-post-thumb-medium{width: 350px; height: 120px;}

.single-post-container{

    margin: 0 0 24px 0;

    h3{
        margin: 24px 0 12px 0;
        font-size: 18px;
        text-transform: capitalize;

        a{
            color: @heading-font-color;

            &:hover{
                color: @text-color;
            }
        }
    }

    .custom-breadcrumb{

        font-size: 18px;        
        margin-bottom: 15px;
        padding-bottom: 10px;
        border-bottom: 1px solid @light-border-color;

    }


    .article-meta-extra{

        border-top: 1px solid @light-border-color;
        border-bottom: 1px solid @light-border-color;
        padding: 6px 0;
        margin-bottom: 12px;

        span{

            margin-right: 5px;

            &:last-child{
                margin-right: 0px;
            }

        }


        i{
            color: @sub-text-color;
            margin-right: 3px;
        }

    }


    h2+p{

        margin-top: 12px;
    }


}

.single-post-content{

    h1{
        margin: 12px 0;
    }

    h2{

        margin: 12px 0;

    }

    h3{
        margin: 12px 0;
    }

    h4{

        margin: 12px 0;

    }

    h5{
        margin: 12px 0;
    }

    h6{
        margin: 12px 0;

    }

}

.articles-nav{
    margin-top: -1.5em;
    margin-bottom: 1.5em;
    padding: 0 2.4em;

    .articles-nav-prev{
        float: left;
    }

    .articles-nav-next{
        float: right;
    }

}

.aligncenter, .alignleft, .alignright, .alignnone {

    margin: 12px 0 24px 0;
    max-width: 100%;
    height: auto; 

}

.aligncenter, img.centered {

    display: block;
    margin: 12px auto;
}


.alignleft {
    float: left;
    /*margin: 7px 12px 7px 0; */
}

.alignright {
    float: right;
    /*margin: 7px 0 7px 12px;  */

}

.alignnone {
    clear:both;
}

.wp-caption{

    text-align:center;

    img{

        max-width: 100%;
        max-height: auto;

    } 

    .wp-cap{
        margin-bottom: 0px;
        font-size: 12px;

    }
}



.post-navigation {

    text-align:center;
    clear: both;

    p {

        text-transform: uppercase;

        a{

            position: relative;
            padding-left: 24px;
            margin-right: 5px;

            &:before{

                content:"";
                position:absolute;
                width:12px;
                height:12px;
                top:4px;
                left:3px;
                background: transparent;
                border: 1px solid @light-border-color;   
                .transition( 0.3s);

            }

            &:hover{

                &:before{

                    background: @primary-color;
                    border: 1px solid @text-color;   

                }


            }

            &:first-child{
                margin-right: 0px;
            }

        }

    }

}



.comments-nav-section,
.post-nav-section{
    margin: 24px 0 0 0;

    p {
        margin-bottom: 0px;
    }

    a{

        .round(0px);
        text-align: center;
        .transition(0.3s);
        border: 1px solid @light-border-color;
        .drop-shadow(0, 1px, 1px, 0, 0);
        background: transparent;
        color: @text-color;
        padding: 6px 12px;

        &:hover{
            border: 1px solid @primary-color;
            color: @primary-color;
            background: transparent;
        }

    }

}

.article-share-section{
    margin: 24px 0 0 0;
    border: 1px solid @light-border-color;
    border-left: 0px;
    border-right: 0px;
    padding: 14px 0 10px 0;
}



.article-author {
    margin-top: 24px;    
    background: @blog-bg-color;
    padding: 30px;
    min-height: 100px;

    border-bottom: 1px solid @light-border-color;
    padding-bottom: 24px;


    .author-avatar img {
        width: 100px;
        max-width: 100%;
        max-height: auto;
        float: right; 
        border: 1px solid @light-border-color;
        padding: 3px;
        margin-top: 0px;
        margin-left: 12px;
    }

    .about_author{
        margin-bottom: 24px;
    }

    .social-icons{
        a{
            display: inline-block;
            padding: 8px 10px;
            text-align: center;
            border: 1px solid darken(@light-border-color, 20%);
            color: @default-font-color;
            margin-right: 3px;

            &:hover{
                color: @primary-color;
            }
        }
    }


}

.related-post {
    margin-top: 24px;    
    background: @blog-bg-color;
    padding: 30px;    

    ul {

        li {
            margin: 5px 0;
            a {

                font-size: 15px;
                position: relative;                      
                .transition( 0.3s);
                color: @default-font-color;

                &:hover {

                    color: @primary-color;

                }


            }


        }
    }
}

// PAGINATION.



ul.pagination {

    li {


        a.page-numbers {

            color: @primary-color;
            .round(0px);
            padding: 6px 12px;
            border: 0px;
            margin: 0 2px;

            &:hover{
                backgroud: red;
            }
        }


        a.current{
            background: @primary-color;
            color: @light-text-color;
            border: 0px;
        } 


        &:first-child {
            .round(0px);
            a {

            }
        }

        &:last-child {

            .round(0px);
            a {
            }
        }
    }

}


// COMMENT LISTS

.comments-area {

    margin-top: 24px;    
    background: @blog-bg-color;
    padding: 30px;

}
.article-add-comments {
    float: right;
    display: inline-block;	
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align:center;
    color:@sub-text-color;		
    border: 1px solid @light-border-color;
    background: @background-color;
    .transition(0.3s);

    &:hover {
        border: 1px solid @primary-color;
        color: @primary-color;

    }


}

.commentslist {

    margin-top: 24px;
    counter-reset: comment-id;

    li{
        list-style-type : none;

        margin-left: 0px;

        &:first-child{

            margin-top: 24px;

        }

    }



    li+li{
        margin-top: 24px;
    }

    li ol, li ul {
        padding-left: 3em;
        margin-left: 3em;
        border-left: 1px dotted @light-border-color;
        list-style-type : none;
    }

    li ol li, li ul li{
        position: relative;
        &::before {
            content: '';
            width: 2em;
            height: 1px;
            border-bottom: 1px dotted @light-border-color;
            position: absolute;
            left: -3em;
            top: 2em;
        }
    }

    li article {
        background: @background-color;
        padding: 12px;


        header { 
            border-bottom: 1px solid @light-border-color;
            font-size:12px;
            margin-bottom: 1.5em;
            position: relative;

            span {				
                color: lighten(@text-color,30%);
            }

            &::before {
                counter-increment: comment-id;
                content: counter(comment-id);
                position: absolute;
                right:5px;
                top: 15px;
                color: @light-color;
                font-size: 32px;
                font-family: @heading-font;
            }

            h5 {
                margin-bottom: 12px;
                text-transform: capitalize;
                color: @heading-font-color;

                a{
                    color: @heading-font-color;
                    &:hover{
                        color: @text-color;
                    }
                }     

                span {
                    font-size: 12px;
                    background: @dark-color;					
                    color: @light-text-color;
                    padding: 3px 5px;
                    font-style: normal;
                    margin-right:.5em;

                }
            }

        }

        .comment_text{
            padding-right: 24px;
        }
    }

}


.comment-avatar img {
    width: 64px;
    height: 64px;
    float: right;
    background: #FFFFFF;                
    margin: 2px;
    border: 1px solid @light-border-color;
    padding: 2px;
    .transition(0.3s);


    &:hover{
        border-color: darken(@light-border-color, 15%);
    }
}  

.waiting-moderation {
    color: lighten(@text-color,30%);

}

.comments-nav-section{
    margin: 48px 0;

    .btn-primary{
        background: @pure-white-bg;

        &:hover{
            background: @pure-white-bg;
        }
    }
}


// COMMENT FROM

#comment-form{

    label{
        margin-bottom: 12px;
    }

    input[type=text],
    input[type=email],
    input[type=url],
    input[type=password]{
        border: 0px;
        border-bottom: 1px solid @border-color;
        line-height: 42px;
        height: 42px;
        margin-bottom: 24px;
        padding: 0 12px;
        .round(0);
        .drop-shadow(0,0,0,0);
        .inner-shadow(0,0,0,0);
        .transition( 0.3s);
        &:focus{
            .drop-shadow(0,0,0,0);
            .inner-shadow(0,0,0,0);

            border-bottom: 1px solid lighten(@dark-border-color,50%);

        }

    }


    textarea{
        border: 0px;
        border-bottom: 1px solid @border-color;
        padding: 12px;
        margin-bottom: 24px;
        .round(0);
        .drop-shadow(0,0,0,0);
        .inner-shadow(0,0,0,0);
        .transition( 0.3s);
        &:focus{

            .drop-shadow(0,0,0,0);
            .inner-shadow(0,0,0,0);
            border: 0px;
            border-bottom: 1px solid lighten(@dark-border-color,50%);

        }

    }

}


/***********************************************************************************************/
/* 10. ABOUT US PAGE */
/***********************************************************************************************/

.about-us-header{
    background-image: url("../images/counter_bg_2.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    overflow: hidden;
}


.section-heading-bold{

    position: relative;
    font-size: 32px;
    margin-bottom: 48px;
    line-height: 48px;

}

.section-how-we-work{

    padding-bottom: 60px;

    .service-block{
        background: @pure-white-bg;
        border: 1px solid @light-border-color;
        position: relative;
        margin-bottom: 24px;
        padding: 24px 24px;

        span.fa{
            font-size: 42px;
            right: 12px;
            bottom: 12px;
            opacity: 0.1;
            position: absolute;
            .transition(all, 0.9s);
        }

        h2{

            text-transform: uppercase;
            font-size: 18px;
            color: @default-font-color;
            font-family: @heading-font-alt;
            font-weight: 500;
            position: relative;

            &:after{

                .transition(all, 0.3s);
                position: absolute;
                content: '';
                bottom: -8px;
                left: 0;
                display: block;
                width: 60px;
                height: 2px;
                background: @primary-color;

            }


        }

        p{
            color: lighten(@default-font-color, 17%);
        }

        &:hover{

            span.fa{
                color: @primary-color;
                transform: rotateY(360deg);
                opacity: 0.2;
            }

            h2{

                &:after{

                    background: lighten(@primary-color, 30%);
                    width: 32px;
                    height: 2px;
                }

            }

        }

    }

    .service-img-block{

    }
}

.section-kb-features{

    text-align: center;
    padding-bottom: 42px;

    .kb-feat-block{
        background: @pure-white-bg;
        border: 1px solid @light-border-color;
        position: relative;
        margin: 12px 0 42px;
        padding: 24px 24px;

        span.fa{
            font-size: 24px;
            left: 50%;
            width: 42px;
            height: 42px;
            line-height: 42px;
            margin-left: -21px;
            top: -21px;
            opacity: 1;
            position: absolute;
            .transition(all, 0.9s);
            background: @primary-color;
            color: @light-text-color;
        }

        h2{
            margin-top: 12px;
            text-transform: uppercase;
            font-size: 18px;
            color: @default-font-color;
            font-family: @heading-font-alt;
            font-weight: 500;
            position: relative;

            &:after{

                .transition(all, 0.3s);
                position: absolute;
                content: '';
                bottom: -8px;
                left: 50%;
                display: block;
                width: 32px;
                margin-left: -16px;
                height: 2px;
                background: @primary-color;

            }


        }

        p{
            color: lighten(@default-font-color, 17%);
        }

        &:hover{

            span.fa{
                color:  lighten(@default-font-color, 17%);
                background: lighten(@primary-color, 30%);
            }

            h2{

                &:after{

                    background: lighten(@primary-color, 30%);
                    width: 32px;
                    height: 2px;
                }

            }

        }

    }

    .service-img-block{

    }

}

/***********************************************************************************************/
/* 10. ELEMENTS */
/***********************************************************************************************/

/*----- 10.1 INFO BOX  ----*/

.section-info-box{
    padding-bottom: 42px;
}

.rounded-info-icon{
    .round(100%);
}

/*----- 10.1.1 INFO BOX LAYOUT 01 ----*/

.info-box-layout-1{

    text-align: center;
    background: @pure-white-bg;
    border: 1px solid @light-border-color;
    position: relative;
    margin: 12px 0 42px;
    padding: 24px 24px;

    span.fa{
        font-size: 24px;
        left: 50%;
        width: 42px;
        height: 42px;
        line-height: 42px;
        margin-left: -21px;
        top: -21px;
        opacity: 1;
        position: absolute;
        .transition(all, 0.9s);
        background: @primary-color;
        color: @light-text-color;
    }

    h2{
        margin-top: 12px;
        text-transform: uppercase;
        font-size: 18px;
        color: @default-font-color;
        font-family: @heading-font-alt;
        font-weight: 500;
        position: relative;

        &:after{
            .transition(all, 0.3s);
            position: absolute;
            content: '';
            bottom: -8px;
            left: 50%;
            display: block;
            width: 32px;
            margin-left: -16px;
            height: 2px;
            background: @primary-color;
        }

    }

    p{
        color: lighten(@default-font-color, 10%);
    }

    a.btn{
        background: transparent;
        color: lighten(@default-font-color, 1%);
        border: 1px solid @light-border-color;
        .round(0);
    }

    &:hover{

        background: lighten( @light-bg, .5%);

        span.fa{
            color: @light-text-color;
            background: darken(@primary-color, 15%);
        }

        h2{

            &:after{
                background: lighten(@primary-color, 30%);
                width: 32px;
                height: 2px;
            }

        }

        a.btn{

            background: @primary-color;
            color: @light-text-color;
            border: 1px solid @primary-color;
        }

    }

}

/*----- 10.1.2 INFO BOX LAYOUT 02 ----*/

.info-box-layout-2{

    text-align: left;
    background: @pure-white-bg;
    border: 1px solid @light-border-color;
    position: relative;
    margin: 12px 0;
    padding: 16px;

    h2{
        text-transform: uppercase;
        font-size: 18px;
        color: @default-font-color;
        font-family: @heading-font-alt;
        font-weight: 500;
        position: relative;

        &:after{
            .transition(all, 0.3s);
            position: absolute;
            content: '';
            bottom: -8px;
            left: 0;
            display: block;
            width: 32px;
            height: 2px;
            background: @primary-color;
        }

    }

    p{
        color: lighten(@default-font-color, 10%);
    }

    a.btn{
        background: transparent;
        color: lighten(@default-font-color, 1%);
        border: 1px solid @light-border-color;
        .round(0);
    }

    &:hover{

        background: lighten( @light-bg, .5%);

        h2{

            &:after{
                width: 48px;
                height: 2px;
            }

        }

        a.btn{

            background: @primary-color;
            color: @light-text-color;
            border: 1px solid @primary-color;
        }

    }

}

/*----- 10.1.3 INFO BOX LAYOUT 03 ----*/

.info-box-layout-3{
    background: @pure-white-bg;
    border: 1px solid @light-border-color;
    position: relative;
    margin-bottom: 24px;
    padding: 16px;

    span.fa{
        font-size: 36px;
        right: 12px;
        top: 24px;
        opacity: 0.1;
        position: absolute;
        .transition(all, 0.9s);
    }

    h2{

        text-transform: uppercase;
        font-size: 18px;
        color: @default-font-color;
        font-family: @heading-font-alt;
        font-weight: 500;
        position: relative;

        &:after{

            .transition(all, 0.3s);
            position: absolute;
            content: '';
            bottom: -8px;
            left: 0;
            display: block;
            width: 60px;
            height: 2px;
            background: @primary-color;

        }


    }

    p{
        color: lighten(@default-font-color, 17%);
    }

    a.btn{
        background: transparent;
        color: lighten(@default-font-color, 1%);
        border: 1px solid @light-border-color;
        .round(0);
    }

    &:hover{

        span.fa{
            color: @primary-color;
            transform: rotateY(360deg);
            opacity: 0.2;
        }

        h2{

            &:after{

                background: lighten(@primary-color, 30%);
                width: 32px;
                height: 2px;
            }

        }

        a.btn{

            background: @primary-color;
            color: @light-text-color;
            border: 1px solid @primary-color;

        }

    }

}

/*----- 10.2 COUNTER/FUN FACTS  ----*/

/*----- 10.2.1 COUNTER LAYOUT 01 ----*/

.ff-counter-layout-1{
    
    padding: 24px 12px;
    clear: both;

    .icon{
        font-size: 36px;
        display: block;
        margin-bottom: 16px;
        color: lighten(@text-color,10%);
    }
    
    .counter{
        margin-top: 24px;
        font-size: 36px;
        color: @primary-color;
        font-weight: 400;
    }

    h4{
        position: relative;
        margin-top: 12px;
        margin-bottom: 0px;
        font-weight: 400;
        font-size: 18px;
        text-transform: uppercase;
        color: @text-color;
        letter-spacing: 0.03em;

        &:before{

            .transition(all, 0.3s);
            position: absolute;
            content: '';
            top: -3px;
            left: 50%;
            margin-left: -16px;
            display: block;
            width: 32px;
            height: 1px;
            background: darken( @light-border-color, 10%);

        }

    }

}

.ff-counter-light-bg{
    
    .transition(all, 0.5s);
    background: @light-bg;
    
    &:hover{
        background: darken( @light-bg, 1%);
    }
    
}

/*----- 10.2.2 COUNTER LAYOUT 02 ----*/

.ff-counter-layout-2{
    
    padding: 24px 12px;
    clear: both;

    .icon{
        font-size: 32px;
        display: block;
        margin-bottom: 24px;
        color: lighten(@default-font-color, 20%);
    }
    
    .counter{
        margin-top: 24px;
        font-size: 32px;
        color: @primary-color;
        font-weight: 400;
    }

    h4{
        margin: 0px;
        font-family: @body-font;
        font-weight: 400;
        font-size: 16px;
        text-transform: uppercase;
        color: @light-text-color;
        letter-spacing: 0.03em;
    }

}

/*----- 10.2.3 COUNTER LAYOUT 03 ----*/

.ff-counter-layout-3{
    
    border: 1px solid @light-border-color;
    clear: both;
    padding: 24px 24px 16px 24px;
    margin-bottom: 28px;
    background: rgba(255,255,255,0.9);

    .icon{
        color: lighten(@default-font-color, 10%);
        float: left;
        font-size: 36px;
        margin: 1px 15px 0 0;
    }

    .count-info{
        overflow: hidden;
    }

    .counter{
        margin-top: 24px;
        font-size: 36px;
        color: @primary-color;
        font-weight: 400;
        font-family: @heading-font-alt;
    }

    h4{
        margin-top: 0px;
        font-family: @body-font;
        font-weight: 400;
        font-size: 16px;
        text-align: left;
        text-transform: uppercase;
        color: lighten(@default-font-color, 10%);
        letter-spacing: 0.04em;
        margin-bottom: 0px;
    }

}

/*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/



// SECTION TOPICS.

.section-kb-topics{
    padding-bottom: 60px;
    text-align: center;

    .kb-topics-block{
        background: @pure-white-bg;
        border: 1px solid @light-border-color;
        position: relative;
        margin: 0 0 24px 0px;
        padding: 24px 24px;

        span.fa{
            font-size: 60px;
            opacity: 1;
            .transition(all, 0.9s);
            color: @primary-color;
        }

        h2{

            margin-top: 24px;
            text-transform: uppercase;
            font-size: 18px;
            font-family: @heading-font-alt;
            font-weight: 500;
            position: relative;

            a{
                color: @default-font-color;
            }

        }

        p{
            color: lighten(@default-font-color, 17%);
        }

        &:hover{

            span.fa{
                color:  lighten(@default-font-color, 17%);

            }

            h2{

                a{
                    color: @primary-color;
                }

            }

        }

    }

    .service-img-block{

    }
}

// SECTION CATEGORIES

.section-kb-categories{
    padding-bottom: 70px;
    position: relative;
    overflow: hidden;

    .kb-cat-block{
        background: @pure-white-bg;
        border: 1px solid @light-border-color;
        position: relative;
        margin-bottom: 42px;
        padding: 24px 24px;

        .icon{
            font-size: 42px;
            right: 16px;
            top: 20px;
            opacity: 0.2;
            position: absolute;
            .transition(all, 0.9s);
        }

        h2{
            font-size: 18px;
            color: @default-font-color;
            font-family: @heading-font-alt;
            font-weight: 500;
            position: relative;

            span.cat-counter{
                display: inline-block;
                background: @primary-color;
                color: @light-text-color;
                .round(100%);
                width: 28px;
                height: 28px;
                line-height: 28px;
                text-align: center;
                font-size: 14px;
                position: relative;
                top: 0;
                left: 6px;
            }

            &:after{

                .transition(all, 0.3s);
                position: absolute;
                content: '';
                bottom: -8px;
                left: 0;
                display: block;
                width: 32px;
                height: 2px;
                background: @primary-color;

            }


        }

        p{
            color: lighten(@default-font-color, 3%);
            font-weight: 400;
        }

        &:hover{

            .icon{
                color: @primary-color;
                transform: rotateY(360deg);
                opacity: 1;
            }

            h2{

                &:after{

                    background: lighten(@primary-color, 30%);
                    width: 60px;
                    height: 2px;
                }

            }

        }



    }

}

// KB CATEGORY LISTS

.kb-cat-lists{

    position: relative;
    margin-top: 0;

    .icon{
        font-size: 42px;
        right: 16px;
        top: 20px;
        opacity: 0.2;
        position: absolute;
        .transition(all, 0.9s);
    }

    h2{
        font-size: 18px;
        color: @default-font-color;
        font-family: @heading-font-alt;
        font-weight: 500;
        position: relative;

        span.cat-counter{
            display: inline-block;
            background: @primary-color;
            color: @light-text-color;
            .round(100%);
            width: 28px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            font-size: 14px;
            position: relative;
            top: 0;
            left: 6px;
        }

        &:after{

            .transition(all, 0.3s);
            position: absolute;
            content: '';
            bottom: -8px;
            left: 0;
            display: block;
            width: 32px;
            height: 2px;
            background: @primary-color;

        }


    }

    p{
        color: lighten(@default-font-color, 17%);
    }

    &:hover{

        .icon{
            color: @primary-color;
            transform: rotateY(360deg);
            opacity: 1;
        }

        h2{

            &:after{

                background: lighten(@primary-color, 30%);
                width: 60px;
                height: 2px;
            }

        }

    }



}



/* ---------------Rounded List Style ----------------------- */	

ol.rounded-list{
    counter-reset: li;
    list-style: none;
    padding: 0;
    margin: 24px 0 0 0;
}

ol.rounded-list li{
    margin: 12px 0 12px 16px;
    list-style: none;
}		

.rounded-list a,
.rounded-list a:visited{
    position: relative;
    display: block;
    padding: 0.4em 0.4em 0.4em 20px;
    *padding: .4em;
    margin: .5em 0;
    background: #F1F1F1;
    color: #616161;
    text-decoration: none;
    -webkit-transition: background .5s ease-out;
    -moz-transition: background .5s ease-out;
    -ms-transition: background .5s ease-out;
    -o-transition: background .5s ease-out;
    transition: background .5s ease-out;	
}

.rounded-list a:hover{
    color: #2c2c2c;
    background: #EEEEEE;
}

.rounded-list a:hover:before{
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);	
}

.rounded-list a:before{
    content: counter(li);
    counter-increment: li;
    position: absolute;	
    left: -18px;
    top: 50%;
    margin-top: -16px;
    background: #e8e8e8;
    height: 32px;
    width: 32px;
    line-height: 26px;
    border: 3px solid #FFFFFF;
    text-align: center;
    font-style: normal;
    font-weight: 300;
    color: #2c2c2c;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -ms-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
}



/*----------------------Rectangle List Style-----------------------------------------*/

ol.rectangle-list{
    counter-reset: li;
    padding: 0;
    margin: 24px 0 0 0;
}

ol.rectangle-list li{
    margin: 12px 0 12px 16px;
    list-style: none;
}

.rectangle-list a,
.rectangle-list a:visited{
    position: relative;
    display: block;
    padding: 0.4em 0.4em 0.4em 28px;
    *padding: .4em;
    margin: .5em 0;
    background: #F1F1F1;
    color: #616161;
    text-decoration: none;
    -webkit-transition: background .5s ease-out;
    -moz-transition: background .5s ease-out;
    -ms-transition: background .5s ease-out;
    -o-transition: background .5s ease-out;
    transition: background .5s ease-out;	
}

.rectangle-list a:hover{
    color: #2c2c2c;
    background: #EEEEEE;
}

.rectangle-list a:hover:before{
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);	
}

.rectangle-list a:before{
    content: counter(li);
    counter-increment: li;
    position: absolute;	
    left: -16px;
    top: 50%;
    margin-top: -15px;
    background: #e8e8e8;
    height: 30px;
    width: 30px;
    line-height: 26px;
    border: 2px solid #FFFFFF;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    color: #2c2c2c;
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -ms-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
}

/*------------------------------None List---------------------------------*/

ol.none-list{
    padding: 0;
    margin: 24px 0 0 0;
}

ol.none-list li{
    margin: 12px 0;
    list-style: none;
}

ol.none-list li a,
ol.none-list li a:visited{

    strong{
        font-weight: 600;
        color: @default-font-color;
    }

    padding-right: 3px;
    border: 0px;
    text-decoration: none;
    color: lighten( @default-font-color, 20%);
    display: block;
    .transition(0.4s);
}

ol.none-list li a:hover{

    color: @primary-color;

}

ol.none-list li a:before{
    content: ''
}

.section-our-team{

    padding-bottom: 60px;

    .team-layout-1{
        overflow: hidden;
        text-align: center;
        border: 1px solid @light-border-color;
        margin-bottom: 32px;

        .team-member{

            a{
                display: block;
                position: relative;
                &:after{
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: '';
                    background: @primary-color;
                    opacity: 0.01;
                    width: 100%;
                    height: 100%;
                    .transition(all, 0.4s);
                }
            }

        }

        .team-member img{
            vertical-align: middle;
            width: 100%; 
        }

        .team-meta{

            background: @primary-color;
            padding: 24px 0;

            h3{
                font-family: @heading-font-alt;
                text-transform: uppercase;
                margin: 0;
                font-size: 18px;
                font-weight: 500;
                color: @light-text-color;
            }



            p{

                color: lighten(@light-text-color, 15%);
                font-size: 14px;
                font-family: @heading-font-alt;
                margin: 0;
                text-transform: capitalize;
            }

        }

        .team-social-share{
            opacity:0;
            left: 50%;
            margin-left: -65px;
            position: absolute;
            bottom:  0;
            width: 130px;

            .transition(all, 0.4s);

            a{
                display: inline-block;
                margin: 0 2px;
                background: @pure-white-bg;
                width: 32px;
                height: 32px;
                text-align: center;
                line-height: 32px;
            }


        }

        &:hover{

            .team-member{

                a{
                    display: block;
                    position: relative;
                    &:after{
                        position: absolute;
                        top: 0;
                        left: 0;
                        content: '';
                        background: @primary-color;
                        opacity: 0.1;
                        width: 100%;
                        height: 100%;
                    }
                }

            }


            .team-social-share{
                opacity: 1;
                bottom: 50%;

                a{
                    display: inline-block;
                    margin: 0 2px;
                    border: 1px solid @light-border-color;
                    width: 32px;
                    height: 32px;
                    text-align: center;
                    line-height: 32px;
                }


            }

        }

    }

    .team-layout-2{
        overflow: hidden;
        text-align: center;
        border: 1px solid @light-border-color;
        margin-bottom: 32px;

        .team-member{

            a{
                display: block;
                position: relative;
                &:after{
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: '';
                    background: @primary-color;
                    opacity: 0.01;
                    width: 100%;
                    height: 100%;
                    .transition(all, 0.4s);
                }
            }

        }

        .team-member img{
            vertical-align: middle;
            width: 100%; 
        }

        .team-meta{

            background: @primary-color;
            padding: 24px 0;
            .transition(all, 0.4s);

            h3{
                font-family: @heading-font-alt;
                text-transform: uppercase;
                margin: 0;
                font-size: 18px;
                font-weight: 500;
                color: @light-text-color;
            }



            p{

                color: lighten(@light-text-color, 15%);
                font-size: 14px;
                font-family: @heading-font-alt;
                margin: 0;
                text-transform: capitalize;
            }

        }

        .team-social-share{
            opacity:0;
            left: 50%;
            margin-left: -65px;
            position: absolute;
            top:  0;
            width: 130px;
            .transition(all, 0.4s);

            a{
                display: inline-block;
                margin: 0 2px;
                background: @pure-white-bg;
                width: 32px;
                height: 32px;
                text-align: center;
                line-height: 32px;
            }


        }

        &:hover{

            .team-member{

                a{
                    display: block;
                    position: relative;
                    &:after{
                        position: absolute;
                        top: 0;
                        left: 0;
                        content: '';
                        background: @primary-color;
                        opacity: 0.1;
                        width: 100%;
                        height: 100%;
                    }
                }

            }


            .team-social-share{
                opacity: 1;
                top: 50%;

                a{
                    display: inline-block;
                    margin: 0 2px;
                    border: 1px solid @light-border-color;
                    width: 32px;
                    height: 32px;
                    text-align: center;
                    line-height: 32px;
                }


            }


            .team-meta{

                background: @light-bg;


                h3{
                    color: @primary-color;
                }

                p{
                    color: @primary-color;
                }

            }


        }

    }

    .teams-container{

        [class^="col-"]{
            padding-left: 6px; 
            padding-right: 6px; 
            width: 100%;
        }

        .owl-controls{
            opacity: 0;
            overflow: hidden;
            margin-top: -60px;
            position: absolute;
            top: 50%;
            .transition(all, 0.3s);
            width: 100%;

            .owl-nav{
                text-align: center;
                clear: both;
            }

            .owl-prev{

                float: left;

                i.nav-icon{
                    display: inline-block;
                    color: @light-bg;
                    background: @primary-color;
                    border: 1px solid @light-border-color;
                    width: 42px;
                    height: 42px;
                    line-height: 42px;
                    position: relative;
                    left: -16px;
                    .transition(all, 0.3s);
                }

                &:hover{

                    i.nav-icon{
                        background: @light-bg;
                        color: @primary-color;
                    }
                }

            }

            .owl-next{

                float: right;

                i.nav-icon{
                    display: inline-block;
                    color: @light-bg;
                    background: @primary-color;
                    border: 1px solid @light-border-color;
                    width: 42px;
                    height: 42px;
                    line-height: 42px;
                    position: relative;
                    right: -16px;
                    .transition(all, 0.3s);
                }

                &:hover{

                    i.nav-icon{
                        background: @light-bg;
                        color: @primary-color;
                    }
                }

            }


        }

        &:hover{

            .owl-controls{
                opacity: 1;

                .owl-prev{


                    i.nav-icon{

                        left: 12px;

                    }

                }

                .owl-next{

                    i.nav-icon{

                        right: 12px;

                    }

                }

            }

        }

    }
}

.section-counter-white-bg{
    background: @pure-white-bg !important;

    &:before{
        background: rgba(255,255,255,1) !important;
    }
}

.section-counter-secondary-bg{
    background: @dark-bg !important;

    &:before{
        background: transparent !important;
    }
}

.section-counter{

    padding: 100px 0 60px 0;
    background-image: url("../images/counter_bg.jpg");
    background-repeat: no-repeat;
    background-position: 0 70%;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    overflow: hidden;

    &:before{
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.7);
        content: '';
        width: 100%;
        height: 2000px;
    }

}


.section-counter-2{

    background-image: url("../images/counter_bg_2.jpg");
    background-repeat: no-repeat;
    background-position: 0 70%;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    overflow: hidden;

    &:before{
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.7);
        content: '';
        width: 100%;
        height: 2000px;
    }


    .counter-block-2{

        text-align:center;

        .icon{
            font-size: 48px;
            display: block;
            margin-bottom: 32px;
            color: lighten(@default-font-color, 50%);

        }
        .counter{
            margin-top: 24px;
            font-size: 32px;
            color: @primary-color;
            font-weight: 400;
            font-family: @heading-font-alt;
        }

        h4{
            margin-top: 12px;
            font-family: @body-font;
            font-weight: 400;
            font-family: @heading-font-alt;
            font-size: 16px;
            text-transform: uppercase;
            color: @light-text-color;
            letter-spacing: 0.03em;
        }

    }

}


// Testimonials.

.section-testimonial-white-bg{
    background: @pure-white-bg !important;

    &:before{
        background: rgba(255,255,255,1) !important;
    }
}

.section-testimonial-secondary-bg{
    background: @dark-bg !important;

    &:before{
        background: transparent !important;
    }
}

.section-client-testimonial{

    background-image: url("../images/testimony_bg.jpg");
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    overflow: hidden;

    &:before{
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.1);
        content: '';
        width: 100%;
        height: 2000px;
    }

}

.testimony-layout-1{

    position: relative;
    text-align: left;
    background: rgba(255,255,255,0.95);
    padding: 12px 12px 24px 24px;
    margin-bottom: 12px;

    div.testimony-content{

        position: relative;
        font-family:@body-font;
        font-size: 17px;
        font-weight: 400;
        letter-spacing: 0.03em;
        line-height: 28px;
        margin: 12px 0 0px;
        color: darken( @default-font-color, 5%);

        &:after{
            position: absolute;
            font-family: FontAwesome;
            content: '\f10e';
            right: -16px;
            top: 24px;
            font-size: 120px;
            color: @primary-color;
            opacity: .05;
            font-style: normal;
        }
    }

    .testimony-meta{
        margin-top: 24px;
        clear: both;

        img{
            .round(0);
            width: 60px;
            height: 60px;
            margin: 1px 15px 0 0;
            float: left;
        }

        .testimony-author{

            overflow: hidden;

            h6{
                margin: 0;
                font-size: 18px;
                text-transform: uppercase;

            }

            span{
                font-size: 14px;
                text-transform: uppercase;
                color: lighten(@default-font-color, 10%);
            }
        }


    }

}

.testimonial-container{

    [class^="col-"]{
        padding-left: 6px; 
        padding-right: 6px; 
    }



    .owl-controls{
        margin-top: 0px;

        .owl-nav{
            text-align: center;
        }

        .owl-prev,
        .owl-next{
            display: inline-block;
            margin: 0 4px;
        }
        i.nav-icon{
            display: inline-block;
            background: transparent;
            border: 1px solid darken( @light-border-color, 10%);
            width: 20px;
            height: 9px;
            position: relative;

            &:after{
                position: absolute;
                top: 2px;
                left: 2px;
                width: 14px;
                height: 3px;
                background: lighten(@primary-color, 5%);
                content: '';
            }
        }
    }

}

.btn-cta{
    width: 200px;
    font-weight: bold;
    text-transform: uppercase;
}

// SECTION COUNTER & TESTIMONIAL.

.section-counter-testimonial{

    background-image: url("../images/testimony_bg.jpg");
    background-repeat: no-repeat;
    background-position: 0 60%;
    background-size: cover;
    background-attachment: scroll;
    position: relative;
    overflow: hidden;
    padding-bottom: 60px;

    &:before{
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.1);
        content: '';
        width: 100%;
        height: 2000px;
    }


    // COUNTER BLOCK.

    .counter-block-2{
        border: 1px solid @light-border-color;
        clear: both;
        padding: 24px 24px 16px 24px;
        margin-bottom: 28px;
        background: rgba(255,255,255,0.9);

        .icon{

            color: lighten(@default-font-color, 10%);
            display: block;
            float: left;
            font-size: 36px;
            left: -6px;
            position: relative;
            top: 12px;
            width: 52px;

        }
        .counter{
            margin-top: 24px;
            font-size: 36px;
            color: @primary-color;
            font-weight: 400;
            font-family: @heading-font-alt;
        }

        h4{
            margin-top: 0px;
            font-family: @body-font;
            font-weight: 400;
            font-size: 16px;
            text-align: left;
            text-transform: uppercase;
            color: lighten(@default-font-color, 10%);
            letter-spacing: 0.04em;
            margin-bottom: 0px;
        }

    }


    //TESTIMONIAL BLOCK.

    .testimony-layout-2{

        position: relative;
        text-align: left;
        background: rgba(255,255,255,1);
        padding: 12px 24px 24px 24px;

        p{

            position: relative;
            font-family:@body-font;
            font-size: 18px;
            font-weight: 400;
            letter-spacing: 0.03em;
            line-height: 32px;
            max-width: 512px;
            margin: 12px 0 0px;
            color: darken( @default-font-color, 5%);

            &:after{
                position: absolute;
                font-family: FontAwesome;
                content: '\f10e';
                right: -16px;
                top: 24px;
                font-size: 100px;
                color: @primary-color;
                opacity: .05;
                font-style: normal;
            }
        }


        .testimonial-meta{

            position: relative;
            clear: both;

            img{
                .round(100%);
                width: 60px;
                height: 60px;
                margin: 4px auto 24px auto;
                float: left;
                display: block;
            }

            h6{
                margin: 12px 0 0 66px;
                font-size: 18px;
                text-transform: uppercase;

            }

            span{
                margin: 12px 0 0 6px;
                font-size: 14px;
                text-transform: uppercase;
                color: lighten(@default-font-color, 10%);
            }
        }



    }

    .testimonial-container-2{

        .owl-nav div{
            color: @blog-bg-color;
            font-size: 90px;
            line-height: 140px;
            margin-top: -70px;
            opacity: 0.2;
            position: absolute;
            top: 50%;
            z-index: 1;
        }

        .owl-nav div.owl-prev { left: 30px }
        .owl-nav div.owl-next { right: 30px }
        .owl-nav div:hover { opacity: .4 }

    }


}


.section-client-logo{

    .logo-layout-1{

        .client-logo{

            padding: 6px;
            img{
                .round(0);
                width: 100%;
                margin: 0 auto;
                border: 1px solid @light-border-color;
                display: block;
                padding: 6px;
                opacity: 0.7;
                .transition(all, 0.5s);
            }

            &:hover{
                img{
                    border: 1px solid lighten( @primary-color, 10%);
                    opacity: 1;
                }

            }

        }

    }

    .logo-layout-2{

        .client-logo{

            background: @pure-white-bg;

            img{
                .round(0);
                width: 100%;
                margin: 0 auto;
                border: 1px solid @light-border-color;
                display: block;
                padding: 6px;
                .transition(all, 0.5s);
            }

            &:hover{
                img{
                    border: 1px solid lighten( @primary-color, 10%);
                    opacity: 1;
                }

            }

        }

    }

    .logo-layout-3{

        .logo-container{
            padding: 6px;
            [class^="col-"]{
                padding-left: 6px; 
                padding-right: 6px; 
                width: 100%;
            }
        }


        .client-logo{

            border: 1px solid @light-border-color;
            background: @pure-white-bg; 
            margin-bottom: 0px;

            img{
                .round(0);
                width: 100%;
                margin: 0;
                opacity: 0.6;
                display: block;
                .transition(all, 0.5s);
            }

            &:hover{

                border: 1px solid @primary-color;
                img{
                    opacity: 1;
                }

            }

        }

    }

    .logo-items{

        .owl-controls{
            margin-top: 12px;

            .owl-nav{
                text-align: center;
            }

            .owl-prev,
            .owl-next{
                display: inline;
                margin: 0 4px;
            }
            i.logo-nav-icon{
                display: inline-block;
                background: transparent;
                border: 1px solid darken( @light-border-color, 10%);
                width: 16px;
                height: 16px;
                position: relative;

                &:after{
                    position: absolute;
                    top: 3px;
                    left: 3px;
                    width: 8px;
                    height: 8px;
                    background: lighten(@primary-color, 5%);
                    content: '';
                }
            }
        }

    }

}


/***********************************************************************************************/
/* 11. 404 PAGE */
/***********************************************************************************************/

.section-404{
    background: @second-bg-color;
}

.message-container-404{

    margin: 48px 0;

    .text-404{
        font-size: 170px;
        font-weight: 600;
        letter-spacing: 0.1em;
        font-family: @heading-font-alt;
        text-align: center;
        margin:80px 0;
        color:@primary-color;

        span {
            color: darken(@primary-color,10%);
        }
    }

    .search-form-404 {

        margin: 0 auto;
        padding: 5px 5px;    
        width: 40%;

    }


    .message-text-404{
        padding: 24px 160px;
        display: block;
        text-align: center;

    }

}



/***********************************************************************************************/
/* 12. FOOTER */
/***********************************************************************************************/

footer {

    color: @light-text-color;
    margin-top: 0px;

    .footer-intro{

        margin: 72px 0 32px 0;

        .footer-logo{
            margin-bottom: 24px;
        }

        p{
            font-size: 18px;
        }
    }

    a{
        color: @light-text-color;

        &:hover{
            color: @primary-color;
        }
    }


}

.footer-social-links{

    clear: both;
    margin-top: 24px;
    text-align: center;

    a{
        border: 1px solid @light-border-color;
        color: @light-text-color;
        display: inline-block;
        font-size: 16px;
        height: 42px;
        line-height: 42px;
        margin-right: 8px;
        position: relative;
        text-align: center;
        .transition(all,0.3s);
        width: 42px;
        .round(0px);

        &:hover{
            background: @primary-color;
            color: @light-text-color;
        }
    }

}

.footer-widget-area {

    padding: 50px 0 70px 0;
    background: #333333;
    margin: 0;

    .footer-widget {

        input[type=text],
        input[type=password],
        input[type=submit],
        input[type=button],
        textarea{
            .round(0);                    
            border: 1px solid @footer-sidebar-border;
            &:focus{

                .drop-shadow(0, 0, 6px, 0, 0.2);
                border: 1px solid lighten(@dark-border-color,50%);

            }
        }

        button[type=submit],
        input[type=submit],
        input[type=button]{

            background: @footer-text-color;

        }



    }


    .footer-widget-header {

        margin-bottom: 8px;

        h3{
            border-bottom: 1px solid @light-border-color;
            color: @light-text-color;
            font-size: 24px;
            font-weight: 600;
            text-transform: uppercase;
            padding-bottom: 16px;
            margin-top: 24px;  
            position: relative;

            &:after {
                bottom: -4px;
                content: "";
                height: 7px;
                left: 0;
                position: absolute;
                width: 90px;
                background: @primary-color;
            }
        }

    }

    .footer-subscription{

        margin-top: 24px;

        input[type="email"]{

            color: @light-text-color;
            border: 0px solid #FFF;
            background: #444444;
            height: 48px;

            &:focus{
                box-shadow: none;
            }
        }

        input[type="submit"]{
            border: 0px solid #FFF;
            background: @primary-color;
            color: @light-text-color;
            height: 48px;
            padding: 0 18px;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
    }

    .fa-footer{
        display: inline;
        text-align: center;
        width: 12px;
        height: 12px;
        color: lighten( @primary-color, 5%);
        padding: 4px 6px 6px 0;
        margin-right: 6px;
    }

    .footer-support-hours{
        clear: both;

        .fa-footer{
            display: inline-block;
            text-align: center;
            width: 24px;;
        }

    }

    .footer-widget + .footer-widget {
        margin-top: 24px;
    }

    li{
        line-height: 32px;
    }
}

.footer-widget-area-bg{
    background: @dark-bg;
}

.footer-contents{
    background: darken( @dark-bg, 3%);
    border-top: 1px solid darken( @light-border-color, 70%);
    padding: 28px 0 20px 0;
}

.footer-info-box{
    .transition(0.4s);
    background: @light-bg;
    position: relative;
    text-align: center;
    border: 1px solid @light-border-color;
    margin: 0;
    padding: 72px 12px 24px;
    font-size: 20px;

    p{
        line-height: 16px;
        font-size: 18px;
    }

    h4{
        color: @light-text-color;
        display: none;
    }

    span{
        color: @primary-color;
        .transition(0.4s);
        position: absolute;
        width: 42px;
        height: 42px;
        font-size: 52px;
        line-height: 42px;
        left: 50%;
        top: 18px;
        margin-left: -21px;
        opacity: 0.8;
    }

    &:hover{

        background: @light-bg;
        border: 1px solid darken( @light-border-color, 5%);

        span{
            color: @dark-text-color;
        }

    }

}

.footer-nav{
    float: right;

    li{
        display: inline;
        list-style-type: none;

        a{
            padding: 6px 12px;
            position: relative;

            &:after{
                content: '';
                position: absolute;
                display: inline-block;
                top: 14px; 
                right: 0;
                margin: 0;
                height: 8px;
                width: 1px;
                background: darken(@pure-white-bg, 50%);
            }


        }

        &:last-child{
            a{
                &:after{
                    content: '';
                    position: absolute;
                    display: inline-block;
                    top: 12px; 
                    right: 0;
                    color: @primary-color;
                    margin: 0;
                    height: 8px;
                    width: 0px;
                    background: @pure-white-bg;
                }
            }
        }

    }

}



/***********************************************************************************************/
/* 13. SIDEBAR & WIDGETS */
/***********************************************************************************************/

.widget{
    background: @blog-bg-color;
    margin-bottom: 42px;
    padding: 20px;
}

.site-sidebar{
    .badge{
        background: @primary-color;
        .round(0px);
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.03em;
        padding: 8px;
    }
}

.widget-title {
    border-bottom: 3px solid #eeeeee;
    color: #5c5c5c;
    font-size: 18px;
    padding-bottom: 15px;
    position: relative;

    &:after {
        bottom: -3px;
        content: "";
        height: 3px;
        left: 0;
        position: absolute;
        width: 32px;
        background: @primary-color;
    }

}

.widget ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}
.widget ul li {
    padding: 5px 0;
}
.widget ul li a {

    color: @default-font-color;

    &:hover{
        color: @primary-color;
    }
}

// Search Widget.

.search-form {

    margin: 24px 0 12px;

    span.input-group-addon{

        .round(0px);
        background: @primary-btn-bg;
        border-right: 0px;
        color: @light-text-color;
        font-weight: bold;
        width: 10%;
        padding: 0 20px;

    }

    input[type="text"] {
        box-shadow: 0;

        margin-bottom:0;        
        font-size: 14px;
        padding-left: 5px;
        height: 42px;
        border: 0px;
        box-shadow: none;
        border-bottom: 1px solid @border-color;
        width: 100%;
        .transition(0.3s);
        &:focus{
            box-shadow: none;
            border-bottom: 1px solid darken(@border-color,10%);
        }

    }

}


// Category.

.widget-post-category {

    margin: 11px auto;

    li {
        margin: 5px 0;
        padding: 0;
        text-transform: capitalize;

        a {

            font-size: 15px;
            position: relative;                   
            .transition( 0.3s);

        }

    }

}

// Recent Posts.

.single-recent-post {

    margin-bottom: 26px;
    overflow: hidden;

    a {
        color: @default-font-color;
        margin-bottom: 8px;
        display: block;
        &:hover{
            color: @primary-color;
        }

    }

    span {
        font-size: 12px;
        display: block;
        color: @primary-color;
    }

}

.single-post-thumb {
    float: left;
    margin-bottom: 10px;
    margin-right: 20px;
    margin-top: 5px;
    width: 100px;
}

// Tags.

.widget-recent-tags {

    margin: 0 auto;

    li {
        margin: 0px 5px 7px 0px;
        float: left;

        a{

            padding: 3px 7px;
            text-transform: capitalize;
            text-align: center;           
            border: 1px solid @primary-color;
            display: block;
            background: transparent;
            .transition(all, 0.3s);

            &:hover,
                &:active {                
                background: transparent;
                border: 1px solid @primary-color;
                background: @pure-white-bg;
            }

        }

    }

} 

// User Login.

.user-login-form{

    .btn-custom{
        width: 120px;
    }

    .remember-label{
        text-align: right;
        float: right;

        .remember-me{
            display: inline-block;
            position: relative;
            text-transform: capitalize;
            top: 6px;

        }
    }

    .hr-alt{
        border: 0px;
        border-bottom: 1px solid @primary-color;
    }
}

/***********************************************************************************************/
/* Widget Link Color */
/***********************************************************************************************/

.sidebar-widget{

    a{

        color: @text-color;
        .transition(0.3s);
        &:hover{
            color: @primary-color;

        }

    }

}


/***********************************************************************************************/
/* CALENDAR WIDGET */
/***********************************************************************************************/

#calendar_wrap {

    margin: 0 auto 5px auto;

    #wp-calendar {

        width: 100%; 

        border: 1px solid @light-border-color;
        padding: 2px;

        caption {

            border-color: @light-border-color;
            border-image: none;
            border-style: solid solid none;
            border-width: 1px 1px medium;
            font-weight: bold;
            padding: 10px 0;
            text-align: center;
            text-transform: uppercase;
        }

        thead { 

            border-right: 1px solid @light-border-color;

        }

        thead th {
            border-right: 1px solid @light-border-color;
        }

        th {
            background: #F9F9F9;
            border-bottom: 1px solid @light-border-color;
            border-right: 1px solid @light-border-color;
        }
        tr th, thead th {
            font-weight: bold;
            padding: 9px 0;
            text-align: center;
        }

        tbody {
            color: #aaa; 

            tr td {
                border-bottom: 1px solid @light-border-color;
                border-right: 1px solid @light-border-color;
                padding: 6px;
                text-align: center;
                background: #f5f5f5; 
                .round(0px);
                text-align: center;                     
                .transition(0.3s);

                &:hover{
                    background: @light-bg;
                }
            }

            .pad {
                background: none; 
            }
        }

        tfoot{

            tr td {
                border-bottom: 1px solid @light-border-color;
                border-right: 1px solid @light-border-color;
                padding: 6px;
                text-align: center;
            }

            #next { 
                font-size: 12px; 
                text-transform: uppercase; 
                text-align: center;
            }
            #prev { 
                text-align: center;
                font-size: 12px; 
                text-transform: uppercase; 
                padding-top: 10px; 
            }
        }

        #today {
            background-color: @primary-color;
            color: @light-text-color;
            font-weight: bold;
        }

    }

}

/***********************************************************************************************/
/* 14. RESPONSIVE STYLING */
/***********************************************************************************************/

/* Tablet Portrait size to standard 960 (devices and browsers) */
@media only screen and (max-width: 991px) {

    .top-bar{
        text-align: center;
    }

    .top-ask-question-btn-container{
        text-align: center;
        margin-top: 12px;
        margin-bottom: 24px;
    }

    a.logo{
        text-align: center;
    }

    .navbar-default {

        .navbar-nav li ul.drop-down{
            top: 68px;
        }

        .navbar-header{
            width: 100%;
            text-align: center;
            clear: both;
            float: none ;
            margin: 0 ;
        }

        .navbar-toggle{
            margin: 22px 0 0 0;
            padding: 5px;

            span.icon-bar{
                height: 1px;
            }
        }

        .navbar-right {
            float: none;
            padding:0;
            margin: 12px 48px 0;
        }

        .navbar .navbar-collapse {
            text-align: center;
        }

    }

    .top-location-info{
        display: block;
        text-align: center;
    }

    .main-menu-container{

        float: none;

    }

    .main-header {
        background: @dark-bg;
        position: relative;
    }


    .sticky-wrapper{
        background: rgba(0,0,0,0.8);
    }


    .navbar-nav > li {
        margin-left: 0;
    }
    .nav > li > a {
        padding: 15px 12px;
        padding-bottom: 22px;
    }
    header.one-page .navbar-nav > li > a {
        padding: 15px 5px;
        font-size: 15px;
        padding-bottom: 22px;
    }


    .main-sidebar{
        width: 100%;
        border: 0px solid transparent;
    }

    .logo{

        text-align: center;
    }

    #jumbotron_1{

        .jumbotron-content {

            text-align: center;

            h2,h3 {
                display: none;
            }


        }

    }

    .section-content-block{
        padding: 42px 0;
    }

    .section-kb-features{

        padding-top: 36px;
        .kb-feat-block{
            margin: 42px 0 0 0;
        }

    }

    .section-heading{
        font-size: 24px;
        line-height: 36px;
        font-weight: normal;
        margin-bottom: 36px;

        &:before{


        }
    }

    .section-subheading{
        font-size: 16px;
        line-height: 24px;
    }

    .page-header{

        padding: 80px 0;

    }

    .kb-cat-block{

        padding: 12px;

        span{
            display: none;
        }
    }

    .ask-question-section {

        .ask-question-block{
            padding: 24px 16px;
        }

    }

    .bkb-ques-form-container{
        width: 700px;
        margin: 0 auto;
    }


    .section-news-block {

        padding-bottom: 32px;

        .news-wrapper{
            margin: 0 0 48px 0;
        }

    }

    .about_couter_block{
        margin: 48px 0;
    }

    .page-header{

        padding: 80px 0;

    }
    
    // Footer.
    
    .footer-info-box{
        margin:  24px 0;
    }

    .footer-widget-area {

        padding: 0px 0 32px 0;

    }

    .footer-nav{
        float: none;
        width: 100%;
        margin: 10px auto;
        display: inline-block;
        text-align: center;
    }

    .copyright-text{
        text-align: center;
    }

}

/* All Mobile Sizes (devices and browser) */
@media only screen and (max-width: 767px) {

    .top-bar{
        text-align: center;
    }

    .top-location-info{
        display: block;
        text-align: center;
    }

    .main-menu-container{

        float: none;

    }

    .stuck{
        position: initial;
    }

    .navbar-default {
        
        a{
            
            &:after{
                display: none;
            }
            
        }

        .navbar-toggle {
            margin-top: 24px;
            .round(0px);

            span.icon-bar{
                color: @primary-color;
            }
        }

        .nav > li > a,
        header.one-page .navbar-nav > li > a {
            padding: 5px 15px;
        }

        .navbar .navbar-right {
            width: 100%;
            text-align: left;
        }

        .navbar-collapse{
            background: #0d0d0d;
            margin-top: 12px;
            padding: 12px 0;
        }

        .navbar-nav li ul.drop-down,
        .navbar-nav li ul.drop-down li ul.drop-down.level3 {
            background: transparent;
            border: none;
            -webkit-transform: rotateX(0deg);
            -moz-transform: rotateX(0deg);
            -ms-transform: rotateX(0deg);
            -o-transform: rotateX(0deg);
            transform: rotateX(0deg);
            -webkit-transform: rotateX(0deg);
            -moz-transform: rotateX(0deg);
            -ms-transform: rotateX(0deg);
            -o-transform: rotateX(0deg);
            transform: rotateX(0deg);
            position: relative;
            width: auto;
            visibility: visible;
            opacity: 1;
            top: inherit;
            left: inherit;
        }

        .navbar-nav li ul.drop-down li a,
        .navbar-nav li ul.drop-down li ul.drop-down.level3 li a {
            background: none;
            color: @light-text-color;
            font-size: 16px;
            text-transform: inherit;
            padding: 4px 20px;
        }

        .navbar-nav li ul.drop-down li a:hover,
            .navbar-nav li ul.drop-down li ul.drop-down.level3 li a:hover {
            color: @light-text-color;
        }

        .navbar-nav{

            margin:0px;
            width: 100%;
            text-align: left;
            background: rgba(0,0,0,0.7);

            li{
                a{
                    font-weight: bold;
                    color: @primary-color;
                }
            }

            .drop-down{

                li{
                    a{
                        color: @light-text-color;
                    }
                }


            }
        }

    }

    .logo{
        text-align: center;
    }

    .main-header {
        background: @dark-bg;
        position: relative;
    }


    .sticky-wrapper{
        background: rgba(0,0,0,0.8);
    }


    #jumbotron_1{

        .jumbotron-content {

            text-align: center;

            h2,h3 {
                display: none;
            }


        }

    }

    .section-content-block{
        padding: 32px 0;
    }

    .section-kb-features{
        padding-top: 36px;
        .kb-feat-block{
            margin: 42px 0 0 0;
        }

    }

    .section-heading{
        font-size: 24px;
        line-height: 36px;
        font-weight: normal;
        margin-bottom: 36px;

        &:before{

            display: none;

        }
    }

    .section-subheading{
        font-size: 16px;
        line-height: 24px;
        display: none;
    }

    .page-header{

        padding: 80px 0;

    }

    .kb-cat-block{

        padding: 12px;

        span{
            display: none;
        }
    }

    .ask-question-section {

        .ask-question-block{
            padding: 24px 16px;
        }

    }


    h2.theme-blue-title-bar a{
        line-height: 24px;
    }

    .section-news-block {

        .news-wrapper{
            margin: 0 0 48px 0;
        }

    }


    .about_couter_block{
        margin: 48px 0;
    }

    .page-header{

        padding: 80px 0;

    }

    // Page Header.

    .page-header{
        h3{
            font-size: 28px;
            line-height: 32px;
            margin: 12px 0;
        }
    }

    // 404 PAGE.

    .message-container-404{

        margin: 48px 0;

        .text-404{
            margin:80px 0;
        }

        .search-form-404 {
            padding: 5px;    
            width: 100%;
        }

        .message-text-404{
            padding: 24px 0px;
        }

    }



    .article-container-fix,
    .main-sidebar {
        width: 100%;
        margin-left: 0px;
        border: 0px solid transparent;
    }

    .post-details-container{
        h3 {

            margin-top: 12px;

        }
    }

    .post-box-container {

        .bd-post-image-container{

            margin: 0 auto;

        }

    }

    .image_gallery_carousel{

        margin-bottom: 48px;
    }
    
    // Footer.
    
    .footer-info-box{
        margin:  24px 0;
    }

    .footer-widget-area {

        padding: 0px 0 32px 0;

    }

    .footer-nav{
        float: none;
        width: 100%;
        margin: 10px auto;
        display: inline-block;
        text-align: center;
    }

    .copyright-text{
        text-align: center;
    }

}

/* Mobile Portrait Size to Mobile Landscape Size (devices and browsers) */
@media only screen and (min-width: 0px) and (max-width: 479px) {

    .main-header {
        background: @dark-bg;
        position: relative;
    }


    .sticky-wrapper{
        background: rgba(0,0,0,0.8);
    }

    .top-bar{
        text-align: center;
    }

    .top-location-info{
        display: block;
        text-align: center;
    }

    .main-menu-container{

        float: none;

    }

    a.logo{
        text-align: center;
        width: 80%;
    }

    .navbar-toggle{
        margin: 20px 0 0 0;
        padding: 5px 3px;

        span.icon-bar{
            height: 1px;
        }
    }

    .navbar-default {
        
        a{
            
            &:after{
                display: none;
            }
            
        }

        .navbar-nav{
            margin:0px;
            width: 100%;
            text-align: left;
            background: rgba(0,0,0,0.7);

            li{
                margin-left: 0px;

                a{
                    font-weight: bold;
                    color: @primary-color;
                }
            }

            .drop-down{

                li{
                    a{
                        color: @light-text-color;
                    }
                }


            }
            
            
        }
        

    }

    .logo{

        text-align: center;
    }


    #jumbotron_1{

        .jumbotron-content {

            h2,h3{
                display: none;

            }

            a.btn-jumbotron{
                margin-top: -24px;
            }

        }



    }

    .cta-section-2 h2{
        font-size: 32px;
    }

    .section-content-block{
        padding: 32px 0;
    }

    .section-kb-features{

        padding-top: 36px;

        .kb-feat-block{
            margin: 42px 0 0 0;
        }

    }

    .section-heading{
        font-size: 24px;
        line-height: 36px;
        font-weight: normal;
        margin-bottom: 24px;

        &:before{

            display: none;

        }
    }

    .section-subheading{
        font-size: 16px;
        line-height: 24px;
        display: none;
    }

    .page-header{

        padding: 80px 0;

    }

    .kb-cat-block{

        padding: 12px;

        span{
            display: none;
        }
    }

    .faq-ask-question-section,
    .ask-question-section {

        .ask-question-block{
            padding: 24px 16px;
        }

    }

    .bkb-ques-form-container{
        width: 100%;
    }

    h2.theme-blue-title-bar a{
        line-height: 14px;
    }

    .section-counter{
        padding: 32px 0 0;
    }
    .logo-layout-1,
    .logo-layout-2{

        .logo{

            margin-top: 0px;

        }

    }

    .faq-ask-question-section{
        padding: 0px;
    }

    .section-news-block {

        .news-wrapper{
            margin: 0 0 48px 0;
        }

    }

    // Home 3.

    .cta-section-3{
        h2{
            font-size: 20px;
            line-height: 32px;
        }
    }



    // Page Header.

    .page-header{
        h3{
            font-size: 24px;
            line-height: 32px;
            margin: 12px 0;
        }
    }

    // 404 PAGE.

    .message-container-404{

        margin: 48px 0;

        .text-404{
            margin:80px 0;
        }

        .search-form-404 {
            padding: 5px;    
            width: 100%;
        }

        .message-text-404{
            padding: 24px 0px;
        }

    }
    
    // Footer.
    
    .footer-info-box{
        margin:  24px 0;
    }

    .footer-widget-area {

        padding: 0px 0 32px 0;

    }

    .footer-intro{
        display: none;
    }

    .footer-nav{
        float: none;
        width: 100%;
        margin: 10px auto;
        display: inline-block;
        text-align: center;
    }

    .copyright-text{
        text-align: center;
    }
}