@import 'https://fonts.googleapis.com/css?family=Montserrat:400,700|Source+Sans+Pro:300,400,500,400i,600,700';

@jumbotron-font: 'Montserrat', sans-serif;
@body-font: 'Source Sans Pro', sans-serif;
@menu-font: @body-font;
@heading-font: 'Source Sans Pro', sans-serif;
@heading-font-alt: @jumbotron-font;


@section-bg-light: #F9FAFB;
@primary-color: #40C1F0;
@top-bar-bg: #F9FAFB;
@top-bar-text: @primary-color;
@text-color: #626262;
@light-text-color: #FFFFFF;
@dark-text-color: #1a1a1a;
@default-font-color: #626262; //body font
@pure-white-bg: #FFFFFF;
@pure-black-bg: #000000;
@footer-bg: #2b2b2b;
@second-bg-color: #F9FAFB;
@border-color: #EEE;
@dark-bg: #2c2c2c;
@light-bg: #FAFAFA;
@blog-bg-color: #F9FAFB;


@link-color: #777777;
@link-hover-color: #66CCFF;
@dark-color: #000000;

@light-color: #F8F8F8;

@background-color: #FFFFFF;
@sub-text-color: #888888;

@secondary-color: #4E4E4E;
@light-border-color: #EBEBEB;
@dark-border-color: #212121;
@primary-btn-bg: @primary-color;
@secondary-btn-bg: @secondary-color;

@footer-bg: #3d4345;
@footer-sidebar-bg: #333333;
@footer-text-color: #FFFFFF;
@footer-sidebar-border: #444444;

@heading-font-color: #666666;